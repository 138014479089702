import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
  EntityId,
} from "@reduxjs/toolkit";
import { API } from "common/utils";
import { RootState } from "./rootReducer";
import { MACHINERY } from "./commonTypes";

export const FIELD_PREPARATION_EVENT_REJECTION_PERIOD_DAYS = 30;

export type FieldPreparationEvent = {
  fieldGuids: string[];
  guid: string;
  note?: string;
  machinery: MACHINERY[];
  fuel: number;
  operationalCost?: number;
  operationalCostUnit?: string;
  creationDate?: string;
  fieldPreparationEventDate: string;
};

class FieldPreparationAPI extends API<FieldPreparationEvent> {
  async getAllFieldPreparationEventsForFarm(
    farmGuid: string,
    fieldPreparationEventTimeAfter?: string
  ): Promise<FieldPreparationEvent[]> {
    const fieldPreparationEventTimeAfterParam = fieldPreparationEventTimeAfter
      ? `&fieldPreparationEventTimeAfter=${fieldPreparationEventTimeAfter}`
      : "";
    const res = await fetch(
      `${this.baseUrl}${this.entity}?farmGuid=${farmGuid}${fieldPreparationEventTimeAfterParam}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
          "Content-Type": "application/json",
        },
      }
    );
    return (await res.json()) as FieldPreparationEvent[];
  }
}

const fieldPreparationApi = new FieldPreparationAPI("fieldPreparation");

export const createFieldPreparationEvent = createAsyncThunk(
  "fieldPreparation/create",
  async (fieldPreparationEvent: FieldPreparationEvent, { dispatch }) => {
    const result = await fieldPreparationApi.createOne(fieldPreparationEvent);
    return { ...fieldPreparationEvent, ...result };
  }
);

export const updateFieldPreparationEvent = createAsyncThunk(
  "fieldPreparation/update",
  async (fieldPreparationEvent: FieldPreparationEvent, { dispatch }) => {
    const result = await fieldPreparationApi.updateOne(fieldPreparationEvent);
    return { ...fieldPreparationEvent, ...result };
  }
);

export const fieldPreparationEventAdapter =
  createEntityAdapter<FieldPreparationEvent>({
    // we need this because IDs are stored in a field other than `field.id`
    selectId: (fieldPreparation) => fieldPreparation.guid,
    // Keeps the array sorted by guid
    sortComparer: (a, b) => a.guid.localeCompare(b.guid),
  });

export const { selectAll: selectAllFieldPreparationEvents } =
  fieldPreparationEventAdapter.getSelectors(
    (state: RootState) => state.fieldPreparationEvents
  );

export const getAllFieldPreparationEventsForFarm = createAsyncThunk<
  Promise<Array<FieldPreparationEvent>>,
  { farmGuid: string; fieldPreparationEventTimeAfter?: string }
>(
  "readings/getFieldPreparationEventsBulk",
  async ({ farmGuid, fieldPreparationEventTimeAfter }) => {
    return fieldPreparationApi.getAllFieldPreparationEventsForFarm(
      farmGuid,
      fieldPreparationEventTimeAfter
    );
  }
);

type Status = "success" | "error" | null;
type LoadingState = "idle" | "pending";
type FieldPreparationState = EntityState<FieldPreparationEvent> & {
  loading: LoadingState;
  error: null;
  status: Status;
};

const initialfieldPreparationEventState: FieldPreparationState =
  fieldPreparationEventAdapter.getInitialState({
    loading: "idle",
    error: null,
    status: null,
  });

const fieldPreparationEventSlice = createSlice({
  name: "fieldPreparationEvent",
  initialState: initialfieldPreparationEventState,
  reducers: {
    resetFieldPreparationEntries: () => initialfieldPreparationEventState,
  },
  extraReducers: {
    [createFieldPreparationEvent.pending.type]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.status = null;
      }
    },
    [createFieldPreparationEvent.fulfilled.type]: (
      state,
      action: PayloadAction<FieldPreparationEvent>
    ) => {
      if (state.loading === "pending") {
        fieldPreparationEventAdapter.upsertOne(state, action.payload);
        state.loading = "idle";
        state.status = "success";
      }
    },
    [createFieldPreparationEvent.rejected.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "error";
        state.error = action.error;
      }
    },

    [getAllFieldPreparationEventsForFarm.fulfilled.type]: (state, action) => {
      fieldPreparationEventAdapter.setAll(
        state,
        action.payload as
          | readonly FieldPreparationEvent[]
          | Record<EntityId, FieldPreparationEvent>
      );
    },

    [getAllFieldPreparationEventsForFarm.pending.type]: (state, action) => {
      state.loading = "pending";
      state.status = null;
    },
    [getAllFieldPreparationEventsForFarm.fulfilled.type]: (
      state,
      action: PayloadAction<Array<FieldPreparationEvent>>
    ) => {
      fieldPreparationEventAdapter.upsertMany(state, action.payload);
      state.loading = "idle";
      state.status = "success";
    },
    [getAllFieldPreparationEventsForFarm.rejected.type]: (state, action) => {
      state.loading = "idle";
      state.status = "error";
      state.error = action.error;
    },
    [updateFieldPreparationEvent.pending.type]: (state, action) => {
      state.loading = "pending";
      state.status = null;
    },
    [updateFieldPreparationEvent.fulfilled.type]: (
      state,
      action: PayloadAction<FieldPreparationEvent>
    ) => {
      fieldPreparationEventAdapter.upsertOne(state, action.payload);
      state.loading = "idle";
      state.status = "success";
    },
    [updateFieldPreparationEvent.rejected.type]: (state, action) => {
      state.loading = "idle";
      state.status = "error";
      state.error = action.error;
    },
  },
});
export const { resetFieldPreparationEntries } =
  fieldPreparationEventSlice.actions;
export const fieldPreparationEventReducer = fieldPreparationEventSlice.reducer;
