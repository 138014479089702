export const messages = {
  "settings.title": "Profile",
  "settings.name": "Name",
  "settings.password": "Password",
  "settings.mail": "Email Address",
  "settings.phone": "Phone Number",
  "settings.address": "Billing Address",
  "settings.button.close": "Close",
  "settings.button.save": "Save",
  "settings.edit": "Edit Profile",
  "settings.logout": "Sign out",
  "settings.nodata": "not provided",
  "passwordChange.success": "Password changed",
  "login.heading": "Grazing App",
  "login.dialog.title": "Login",
  "login.dialog.reset": "Forgot Password",
  "login.reset.popover":
    "To reset your password please write a mail to hello@ruumi.io",
  "login.dialog.signup": "Create Account",
  "login.mail.label": "Email",
  "login.password.label": "Password",
  "login.button.label": "Login",
  "signup.heading": "Grazing App",
  "signup.teaser.dialogTitle": "Getting Started",
  "signup.teaser.text":
    "Start your free 60 day trial today. Enter fields and get insights within seconds.",
  "signup.userDetails.dialogTitle": "Account Details",
  "signup.userDetails.text":
    "No payment needed today. Just your contact details so we can get your account set up.",
  "signup.farmDetails.dialogTitle": "Farm Details",
  "signup.farmDetails.text":
    "To provide you with the best possible service, let us know what type of farm you are running.",
  "signup.button.signup": "Sign Up",
  "signup.button.next": "Next",
  "signup.button.back": "Back",
  "signup.button.getStarted": "Get Started",
  "signup.mail.label": "Email",
  "signup.name.label": "Name",
  "signup.phone.label": "Phone",
  "signup.password.label": "Password",
  "signup.size.label": "Size in ha",
  "signup.farmType.label": "Farm Type",
  "signup.TandC":
    "By clicking sign-up you agree to our <a>Data Protection Policy</a>",
  "signup.farmType.beef": "Beef",
  "signup.farmType.beef_sheep": "Beef & Sheep",
  "signup.farmType.sheep": "Sheep",
  "signup.farmType.dairy": "Dairy",
  "signup.farmType.other": "Other",
  "signup.cbInterest.label": "Interested in Carbon Farming",
  "signup.cbInterest.yes": "Yes",
  "signup.cbInterest.no": "No",
  "signup.welcome.title": "Welcome",
  "signup.welcome.text":
    "Here are a few tips to get you started. You can always get in touch if you have questions",
  "signup.welcome.link": "Contact Support",
  "signup.welcome.enterFields.title": "Enter Fields",
  "signup.welcome.enterFields.text":
    "You can draw fields directly on the map with a few clicks. We compute grass covers within seconds for you to see. If you have a large farm you can send us a file with your field boundaries and we will upload them for you.",
  "signup.welcome.enterAnimalGroups.title": "Enter Animal Groups",
  "signup.welcome.enterAnimalGroups.text":
    "We detect grass supply from space, and with the help of your measurements for calibration. But demand is equally important! Create animal groups with as many stock classes as you like. If you want advice for how to get the exact demand per animal just get in touch.",
  "signup.welcome.planRotations.title": "Plan your rotations",
  "signup.welcome.planRotations.text":
    "Once you have fields and animal groups set up, you can move your animals and plan your rotations using the grass wedge. Assign/unassign fields change rotation length and post grazing target. The blue demand line will show you where you have surplus or deficits.",
  "edit.editingField": "Editing Field",
  "edit.addingField": "Adding Field",
  "edit.editingAnimalGroup": "Editing Animal Group",
  "edit.addingAnimalGroup": "Adding Animal Group",
  "edit.fieldname.label": "Fieldname",
  "edit.animalGroupName.label": "Animal Group Name",
  "edit.stockClassName.label": "Animal Type (add new or use template)",
  "edit.stockClassName.feedBudgetPlanner.label": "Stock class name",
  "edit.stockClassNrOfAnimals.label": "Nr. of Animals",
  "edit.stockClassAvgWeight.label": "Average animal weight",
  "edit.minNumber.error": "Must be at least {min}",
  "edit.maxNumber.error": "Must be less or equal to {max}",
  "edit.required.error": "This field is required",
  "edit.number.error": "This field must be a number",
  "edit.stockClassFeedType.label": "Feeding (e.g. 'Dry - Maintenance')",
  "edit.stockClassDemand.label": "Demand per animal per day",
  "edit.stockClassDefaultStockClass.label": "Stock class type",
  "edit.weight.label": "Total Group Weight",
  "edit.demand.label": "Group Demand",
  "edit.button.cancel": "Cancel",
  "edit.button.save": "Save",
  "edit.button.edit": "Edit",
  "edit.button.back": "Back",
  "edit.button.next": "Next",
  "map.drawfield.drawTools.aria": "Drawing tools",
  "map.drawfield.tooltip": "Draw or Edit Outer Field Boundary",
  "map.drawfield.help.tooltip": "Drawing help",
  "map.drawfield.help.modal.title": "Field editor help",
  "map.drawfield.help.modal.welcome":
    "Welcome to the ruumi field editor! Here are a few tips to get you started with drawing fields. You can always get in touch if you have questions",
  "map.drawfield.help.modal.link": "Contact Support",
  "map.drawfield.help.modal.createMode.title": "Creating a new field boundary",
  "map.drawfield.help.modal.createMode.description":
    'When there is no field boundary yet (or after it was deleted), press the <pencilButton></pencilButton> button to switch into the "Create" mode. Then create the new field boundary by clicking on the edges of your field on the map. The field boundary will be drawn following your clicks.',
  "map.drawfield.help.modal.createMode.zoomControls.description":
    "Use <navIcon></navIcon> buttons to zoom in and out for better precision.",
  "map.drawfield.help.modal.createMode.undo.description":
    'If you clicked the wrong location, use the <undoButton></undoButton> button to undo it. It\'s not possible to change the field boundary in the "Create" mode, only to undo step-by-step.',
  "map.drawfield.help.modal.createMode.boundaryCorrectness.description":
    'Please note that the field boundary must be a convex shape with at least 3 points to be saved. When the boundary is correct, it\'s area and perimiter will be visible and "SAVE" button will be active.',
  "map.drawfield.help.modal.createMode.finish.description":
    'When you\'ve finished drawing, either click on the <pencilButton></pencilButton> or hit "Enter" to exit the "Create" mode. Then you can save the field.',
  "map.drawfield.help.modal.editMode.title": "Editing existing field boundary",
  "map.drawfield.help.modal.editMode.description":
    'When the field boundary already exists, switch to the "Edit" mode by pressing the <pencilButton></pencilButton> button.',
  "map.drawfield.help.modal.editMode.move.description":
    "Move existing points around by clicking and dragging them: <movePointImg></movePointImg>. Add new points by clicking on the field boundary (red lines).",
  "map.drawfield.help.modal.editMode.select.description":
    'Double click on the points to select them. Selected points will become red: <selectPointImg></selectPointImg>. Delete selected points with the <deleteButton></deleteButton> button or hit "Delete". Multiple points can be selected and deleted at once. Double click on the selected point again to deselect.',
  "map.drawfield.help.modal.editMode.finish.description":
    'When you\'ve finished editing, either click on the <pencilButton></pencilButton> or hit "Enter" to exit the "Edit" mode. Then you can save the field.',
  "map.drawfield.help.modal.deleteBoundary.title":
    "Deleting the whole field boundary",
  "map.drawfield.help.modal.deleteBoundary.description":
    'Sometimes you might need to re-draw the field boundary from scratch. When not in "Create" or "Edit" mode use the <deleteButton></deleteButton> to delete the whole field boundary. Then switch to the "Create" mode and draw the new boundary.',
  "map.drawfield.help.modal.close": "Close",
  "map.deletefeature.tooltip": "Delete selected field boundary",
  "map.deleteTentativePosition.tooltip":
    "Remove the previous point (also with Delete keyboard key)",
  "map.deleteSelectedHandles.tooltip":
    "Remove the selected field points (also with Delete keyboard key)",
  "map.clickOnPencilButton.hint":
    "Press the ✏️ button, then click on the map to draw the field boundary.",
  "map.clickOnPencilButtonToEdit.hint":
    "Press the ✏️ button to edit or delete the field boundaries.",
  "map.provideThreePoints.hint":
    "Please add at least 3 points to create a convex field boundary.",
  "map.provideValidGeometry.hint":
    "The field geometry is invalid, please create a convex field boundary with at least 3 points.",
  "map.doubleClickToFinish.hint":
    "Click on the last point or the ✏️ button to finish drawing.",
  "map.editingField.hint":
    "Move the points around or click on the boundary to add a new one, doubleclick the points to select. Press the ✏️ button again to finish.",
  "map.movingHandles.hint":
    "Move the points around or doubleclick on the field points to select them.",
  "map.deleteHandles.hint": "Click on selected points again to deselect.",
  "map.unableDeleteHandles.hint":
    "Impossible to remove selected points, as the field boundary must have at least 3 points after removal, please deselect some",
  "map.deletefeature.confirmation.title": "Remove field boundary",
  "map.deletefeature.confirmation.description":
    "Whole field boundary will be removed, do you want to proceed?",
  "map.deletefeature.confirmation.confirm.label": "Remove",
  "map.deletefeature.confirmation.cancel.label": "Keep editing",
  "warning.tooltip.title": "Overgrazed!",
  "fieldinput.area.text": "Area",
  "fieldinput.area.unit": "ha",
  "fieldinput.save.error": "Could not save field",
  "fieldinput.na": "N/A",
  "fieldinput.boundary.error":
    "Please provide a valid field boundary (convex figure, at least 3 points)",
  "fieldinput.assignedTo.silage": "Silage",
  "fieldinput.assignedTo.text": "Assigned to",
  "fieldinput.grassType.notsetyet": "Not set yet",
  "fieldinput.grassType.text": "Grass type",
  "fieldinput.grassType.option.PERMANENT_PASTURE": "Permanent Pasture",
  "fieldinput.grassType.option.HERBAL_LEY": "Herbal Ley",
  "fieldinput.grassType.option.RYE_GRASS": "Rye Grass",
  "fieldinput.grassType.option.RYE_GRASS_AND_CLOVER": "Rye Grass and Clover",
  "fieldinput.grassType.option.WILDFLOWER_MEADOW": "Wildflower Meadow",
  "fieldinput.grassType.option.LUCERNE_SAINFOIN": "Lucerne/Sainfoin",
  "fieldinput.grassType.option.BRASSICAS": "Brassicas",
  "fieldinput.grassType.option.OTHER": "Other",
  "fieldinput.arableType.option.WHEAT": "Wheat",
  "fieldinput.arableType.option.BARLEY": "Barley",
  "fieldinput.arableType.option.OILSEED_RAPE": "Oilseed rape",
  "fieldinput.arableType.option.POTATO": "Potato",
  "fieldinput.arableType.option.MAIZ": "Maiz",
  "fieldinput.arableType.option.CEREALS": "Cereals",
  "fieldinput.arableType.option.VEGETABLES_SALAD": "Vegetables & Salad",
  "fieldinput.arableType.option.OTHERS": "Others",
  "fieldinput.fieldType.option.ARABLE": "Arable",
  "fieldlist.emptyArable": "No arable fields",
  "fieldinput.fieldType.option.GRASS": "Grass",
  "fieldinput.arableType.text": "Crop type",
  "fieldList.arable.fields": "Arable fields",
  "fieldinput.fieldType.text": "Field type",
  " fieldinput.arableType.notsetyet": "Please select",
  "fieldinput.title.mobile": "Enter field details",
  "fieldinput.addField.instructions":
    "Press the ✏️ button above, then click on the map to draw the field boundary. When finished, press the button again.",
  "fieldview.area.text": "Area",
  "fieldview.area.unit": "ha",
  "fieldview.perimeter.text": "Perimeter",
  "fieldview.perimeter.unit": "m",
  "fieldview.availableDryMatter.text": "Available Dry Matter",
  "fieldview.availableDryMatter.unit": "kgDM",
  "fieldview.grazingDaysAhead.text": "Grazing Days Ahead",
  "fieldview.grazingDaysAhead.unit": "{days}d {hours}h",
  "fieldview.growth.text": "Growth Rate",
  "fieldview.growth.unit": "kgDM/ha/day",
  "fieldview.totalCover.text": "Total Cover",
  "fieldview.totalCover.unit": "kgDM/ha",
  "fieldview.notfound": "404 - Not Found!",
  "fieldview.overgrazed": "overgrazed",
  "fieldview.noDryMatterNow.text": "(currently computing)",
  "fieldview.fieldUpdate.success": "Field successfully saved",
  "fieldview.fieldUpdate.error": "Field could not be saved",
  "fieldview.lastUpdated": "Updated",
  "fieldview.assignedto.text": "Assigned to",
  "fieldview.moveAnimals.text": "Move animals here",
  "fieldview.animalsOnThisField.text": "Animals are on this field",
  "popover.availableDM.unit": "kgDM",
  "popover.availableDM.short": "avail",
  "popover.noDryMatterNow.text": "(currently computing)",
  "fieldlist.dryMatterNow.unit": "kgDM/ha",
  "fieldlist.noDryMatterNow.text": "(currently computing)",
  "fieldlist.empty": "No fields assigned yet",
  "fieldlist.emptySilage": "No fields in silage",
  "fieldevents.silage.title": "Cut for silage",
  "fieldevents.silage.dates": "Cut on: {dateCut}",
  "fieldevents.grazed.title": "Grazed",
  "fieldevents.moveIn.title": "Moved in",
  "fieldevents.moveOut.title": "Moved out",
  "fieldevents.grazed.dateIn": "In: {dateIn}",
  "fieldevents.grazed.dateOut": "Out: {dateOut}",
  "fieldevents.event.preCover": "Pre-Cover: {preCover} kgDM/ha",
  "fieldevents.event.postCover": "Post-Cover: {postCover} kgDM/ha",
  "fieldevents.reading.title": "Cover updated",
  "fieldevents.reading.value": "New cover: {value} kgDM/ha",
  "fieldevents.reading.date": "Updated on: {date}",
  "fieldevents.list.title": "Activity",
  "fieldevents.menu.edit": "Edit",
  "fieldevents.menu.accept": "Accept",
  "fieldevents.menu.reject": "Reject",
  "fieldevents.menu.delete": "Delete",
  "fieldevents.notify.deleted": "Event deleted",
  "fieldevents.notify.accepted": "Autodetected event accepted",
  "fieldevents.notify.rejected": "Autodetected event rejected",
  "fieldevent.autodetected.tooltip": "Autodetected grazing event",
  "fieldevent.manualinput.tooltip": "Event input by you",
  "farm.growth.label": "Growth Rate",
  "farm.growth.unit": "kgDM/ha/day",
  "farm.totalDM.label": "Total Cover Average",
  "farm.totalDM.unit": "kgDM/ha",
  "farm.addMenu.field": "Add Field",
  "farm.addMenu.cover": "Add Cover",
  "farm.addMenu.addGrassTypes": "Add Grass Types",
  "farm.addMenu.farm": "Add Farm",
  "farm.addMenu.animalGroup": "Add Animal Group",
  "farm.add.addName.mobile": "Add Farm Name",
  "farm.add.addName.desktop": "Enter the name of the farm or site",
  "farm.add.saveLocation.desktop": "Find the location of the farm on the map ",
  "farm.add.saveFarm.desktop": ", then press save to create the farm.",
  "farm.add.saveLocation.mobile": "Save Location",
  "farm.add.addName.error": "Please enter a name",
  "farm.add.locationInstructions":
    "Zoom to the location of your farm or use the postcode search at the top, then press save.",
  "addCovers.next.label": "Next",
  "addCovers.back.label": "Back",
  "addCovers.cancel.label": "Cancel",
  "addCovers.save.label": "Save",
  "addCovers.enterTime.heading": "Enter Time",
  "addCovers.enterTime.subheading": "Please provide the time of your readings.",
  "addCovers.enterTime.dateLabel": "Date of Measurement",
  "addCovers.enterTime.startTimeLabel": "Start Time",
  "addCovers.enterTime.endTimeLabel": "End Time",
  "addCovers.enterTime.invalidFinishTime":
    "Please provide a finish time that's after start time.",
  "addCovers.enterCovers.heading": "Enter Covers",
  "addCovers.enterCovers.subheading": "Please enter your covers.",
  "addCovers.enterCovers.fieldName": "Field",
  "addCovers.enterCovers.current": "Current",
  "addCovers.enterCovers.new": "New",
  "addCovers.singleEvent.title": "Enter Event",
  "addCovers.singleEvent.tooltip": "Enter grazing event",
  "addCovers.single.unit": "kgDM/ha",
  "addCovers.single.newCover": "Measured Cover",
  "addCovers.single.measurementDatetime": "Time of Measurement",
  "addCovers.enterReasons.heading": "Farming Events",
  "addCovers.enterReasons.subheading":
    "Looks like we missed a few farming events. What happened?",
  "addCovers.enterReasons.fieldName": "Field",
  "addCovers.enterReasons.difference": "Difference",
  "addCovers.enterReasons.reason": "Reason",
  "addCovers.enterReasons.menu.grazed": "Grazed",
  "addCovers.enterReasons.menu.partGrazed": "Part-Grazed",
  "addCovers.enterReasons.menu.silage": "Silage",
  "addCovers.enterReasons.menu.noEvent": "No Event",
  "addCovers.eventInput.preCover.Label": "Pre Cover",
  "addCovers.eventInput.postCover.Label": "Post Cover",
  "addCovers.eventInput.postCover.Error":
    "Post Cover must be lower than Pre Cover",
  "addCovers.eventInput.dateCut.Label": "Date Cut",
  "addCovers.eventInput.dateIn.Label": "Date In",
  "addCovers.eventInput.dateOut.Label": "Date Out",
  "addCovers.eventInput.dateOut.Error": "Date Out must be after Date In.",
  "addCovers.confirmCovers.heading": "Confirm and Save",
  "addCovers.confirmCovers.noData":
    "No data entered. Please go back to enter your covers",
  "addCovers.confirmCovers.accuracy":
    "Of our readings were within {threshold} kgDM/ha of the covers you entered.",
  "addCovers.confirmCovers.datetime":
    "Your cover readings for {readingsDate} from {startTime} - {endTime} will be saved.",
  "addCovers.confirmCovers.infoMessage":
    "Your map and grass wedges will be updated.",
  "addCovers.success": "Readings succesfully saved.",
  "addCovers.failure": "Readings were not sucessfully saved.",

  "confirmCovers.thumbsUp.tooltip": "Cover looks good - click to confirm",
  "confirmCovers.thumbsUp.title": "Cover looks good!",
  "confirmCovers.thumbsUp.text":
    "<p>This means the reading is accurate enough for you to take a grazing management decision.</p> <p>Please confirm the reading to help us learn from this.</p>",
  "confirmCovers.thumbsUp.cancel": "Cancel",
  "confirmCovers.thumbsUp.save": "Confirm",

  "confirmCovers.thumbsDown.tooltip":
    "Cover is not accurate - click to update cover",
  "confirmCovers.thumbsDown.title": "Cover is not accurate!",
  "confirmCovers.thumbsDown.text":
    "<p>This means the reading is not accurate enough for you to take a grazing management decision. </p> <p>Please enter the reading so we can learn and improve future readings.</p>",
  "confirmCovers.thumbsDown.cancel": "Cancel",
  "confirmCovers.thumbsDown.save": "Confirm",

  "reseedEvent.heading": "Re-seeding Events",
  "addDate.enterTime.dateLabel": "Date",
  "reseedEvents.selectFields.text": "Field(s)",
  "reseedEvent.grassType.text": "To:",
  "reseedEvent.notes.label": "Notes",
  "reseedEvents.show.title": "Reseeded Field",
  "reseedEvents.show.titleName.RESEED": "Reseeded {name}",
  "reseedEvents.show.titleName.OVERSEED": "Overseeded {name}",
  "reseedEvent.reseedDate": "Date: {reseedDate}",
  "reseedEvent.newGrassType": "Grass type: {newGrassType}",
  "editReseedEvent.heading": "Edit reseed event",
  "viewReseedEvent.heading": "View reseed event",
  "editReseedEvent.field.heading": "Field name",
  "editReseedEvent.success": "Succesfully updated reseed event",
  "farm.addMenu.addReseedEvent": "Add reseed events",
  "addGrassType.save.label": "Save",
  "addGrassType.cancel.label": "Cancel",
  "addGrassType.enterGrassType.heading": "Select Grass Types",
  "addGrassType.enterGrassType.subheading":
    "Please select grass types for fields that have not been assigned a grass type yet.",
  "addGrassType.enterGrassType.tableHeading": "Select Grass Types",
  "addGrassType.enterGrassType.fieldName": "Field",
  "addGrassType.enterGrassType.selection": "Grass Types",
  "updateGrassTypes.success": "Successfully updated grass types",
  "fieldlistmenu.silage.label": "Move to Silage",
  "fieldlistmenu.animalGroup.label": "Move to {group}",
  "fieldlistmenu.edit.label": "Edit Field",
  "fieldlistmenu.delete.label": "Delete Field",
  "fieldlistmenu.moveIn.label": "Move Animals Here",
  "fieldlistmenu.moveIn.tooltip.silage":
    "You can not move animals into a silage field. We treat fields not assigned to an animal group as silage fields.",
  "fieldlistmenu.moveIn.tooltip.sameField":
    'To move your animals to a different field, choose the field you want to move the animals to and click "Move Animals Here"',
  "confirmdeletedialog.cancel.label": "Cancel",
  "confirmdeletedialog.confirm.label": "Delete",
  "confirmationdeletedialog.animalGroup.title": "Delete Animal Group",
  "confirmationdeletedialog.animalGroup.description":
    'Are you sure you want to delete "{name}"?',
  "confirmDeleteFieldDialog.text":
    "You have chosen to delete {fieldName}. Are you sure?",
  "confirmDeleteFieldDialog.title": "Delete Field",
  "confirmcanceldialog.cancel.label": "Keep editing",
  "confirmcanceldialog.confirm.label": "Discard edits",
  "confirmcanceldialog.text":
    "If you cancel you will loose all changes. Do you want to proceed?",
  "confirmcanceldialog.title": "Discard Edits",
  "toolbar.farmview.title": "Farm",
  "toolbar.fieldview.title": "Field Detail",
  "toolbar.grasstype-reseeding.title": "Grass type & Re-seeding",
  "map.cursorTooltip.unit": "kgDM/ha",
  "hint.welcome.title": "Welcome ",
  "hint.welcome.text": "Hit the + button to enter your first field",
  "hint.animalGroups.title": "Add an Animal Group ",
  "hint.animalGroups.text": "Hit the + button to create your first group",
  "animalGroup.menu.edit": "Edit Animal Group",
  "animalGroup.menu.delete": "Delete Animal Group",
  "animalGroup.groupWeight": "Total Group Weight",
  "animalGroup.groupDemand": "Demand",
  "animalGroup.noFieldsAssigned": "No fields assigned yet",
  "animalGroup.demandUnit": "{demandPerHaAndDay} kgDM/ha/day",
  "animalGroup.animalCount": "Nr. of Animals in Group",
  "animalGroupInput.boundary.error": "Something went wrong",
  "animalGroupInput.addStockClass": "Add Another Stock Class",
  "animalGroupInput.demand":
    "{groupDemandInKg} kgDM ({groupDemandInPercent}%) per day",
  "animalGroupInput.stockClasses": "Stockclasses",
  "animalGroupInput.stockClasses.birthingPeriods": "Lambing/Calving details",
  "animalGroupInput.stockClasses.birthingPeriods.dateStart": "Beginning",
  "animalGroupInput.stockClasses.birthingPeriods.dateEnd": "End",
  "animalGroupInput.stockClasses.birthingPeriods.expectedNumAnimalsWithoutPregnancy":
    "Nr. animals not pregnant",
  "animalGroupInput.stockClasses.birthingPeriods.expectedNumOffspringPerAnimal":
    "Expected number of calves/lambs per animal",
  "animalGroups.overview.demand": "Demand: {demand} kgDM/ha/day",
  "animalGroups.overview.availableDryMatter":
    "Available Dry Matter: {availableDryMatter} kgDM",
  "animalGroups.overview.averageCover": "Average Cover: {averageCover} kgDM/ha",
  "toolbar.animalGroupView.title": "Animal Group",
  "silage.title": "Silage",
  "silage.description": "Average Cover: {averageCover} kgDM/ha",
  "units.kilogramPerHectar": "kgDM/ha",
  "units.days": "Days",
  "grassWedge.open": "Open grass wedge",
  "grassWedge.postGrazingTarget": "Post-grazing Target",
  "grassWedge.preGrazingTarget": "Computed Pre-grazing Target",
  "grassWedge.rotationLength": "Rotation Length",
  "grassWedge.notification.saved": "Saved",
  "grassWedge.notification.error": "Something went wrong.",
  "grassWedge.averageCover": "Average Cover KgDM/Ha",
  "grassWedge.demand": "Demand KgDM/ha/day",
  "grassWedge.grazingDaysAhead": "Grazing Days Ahead",
  "grassWedge.growth": "Growth Rate kgDM/ha/day",
  "grassWedge.tooltip.area": "Area: {area} ha",
  "grassWedge.tooltip.currentDM": "Total Cover: {dm} kgDM/ha",
  "grassWedge.tooltip.grazindDaysAhead": "Grazing Days Ahead: {days}d {hours}h",
  "grassWedge.noFields.prompt":
    "Assign at least two fields to utilise the grasswedge",
  "grassWedge.singleField.prompt":
    "Assign another field to utilise the grasswedge",
  "fieldlistmenu.notification.movedAnimalGroup":
    "{fieldName} moved to {animalGroupName}",
  "fieldlistmenu.notification.movedToSilage": "{fieldName} moved to Silage",
  "fieldlistmenu.notification.moveFailed": "{fieldName} couldn't be moved",
  "fieldlistmenu.notification.editFailed": "{fieldName} couldn't be edited",
  "fieldlistmenu.notification.moveInFailed":
    "Animals couldn't be moved to {fieldName}",
  "fieldlistmenu.notification.moveInSuccess":
    "Animals have been moved to {fieldName}",
  "grassWedge.assignField": "Assign Field",
  "animalGroup.assignField": "Assign Field",
  "animalGroup.viewFeedAllocation": "View Feed Allocation",
  "assignFieldDialog.title": "Assign Field to this Animal Group",
  "assignFieldDialog.inputLabel": "Field",
  "assignFieldDialog.nofieldsLabel": "No fields available",
  "assignFieldDialog.button": "Assign",
  "animalGroupInput.add.success": "{animalGroupName} created",
  "animalGroupInput.edit.success": "{animalGroupName} updated",
  "fieldInput.add.success": "{fieldName} created",
  "fieldInput.edit.success": "{fieldName} updated",
  "farmInput.add.success": "{farmName} created",
  "animalGroup.grassWedgeButton.tooltip":
    "At least two fields with dry matter required for a grass wedge",
  "fieldProposedEventsModal.headline": "New grazing events detected",
  "fieldProposedEventsModal.description":
    "Accept or reject the auto-detected grazing or mowing events below to update your cover readings.",
  "fieldProposedEventsModal.description1":
    "ruumi now detects when a field was last grazed or mown. We do this with satellite radar which passes every 2-3 days and penetrates cloud cover. At the moment, grazing event suggestions are added to your field history when you accept or reject them.",
  "fieldProposedEventsModal.description2":
    "Tap the 'tick' button if you think ruumi is correct or the 'cross' button if not. You can use the 'pencil' button to add entry and exit times, pre and post grazing covers. Use the 'plus' button to add a new event to the field.",
  "fieldProposedEventsModal.description3":
    "By default ruumi sets fields back to 1500 KgDM/ha after grazing or the residual setting you provide in the group's grass wedge.",
  "fieldProposedEventsModal.description4":
    "Also you can find detected events if you click on a field and look in the bottom lefthand corner.",
  "fieldProposedEventsModal.close": "Close",
  "fieldProposedEventsModal.help": "Help",
  "certificationProgramApplicationModal.headline":
    "ruumi certification program",
  "certificationProgramApplicationModal.description":
    "By using ruumi you build a rich dataset of your farming activities. We can use this data to create the highest value carbon credits. Click below to apply for our carbon credit certification program.",
  "certificationProgramApplicationModal.cta":
    "Apply for ruumi carbon certificates",
  "carbon.profile.cta.text":
    "Find out how you can finance your transition to regenerative farming - ",
  "carbon.profile.cta.link": "<Link>book a free consultation</Link>.",
  "after_first_field_created.field.dialog.title": "Congratulations!",
  "after_first_field_created.field.dialog.text":
    "Now that you have created your first field, you can continue mapping the rest of the fields, or you can add animal groups and assign them to fields. Then, it's time to move the animals in and start rotational grazing!",
  "after_field_created.field.dialog.text": "Field Created!",
  "after_field_created.field.dialog.close": "Close",
  "after_field_created.field.dialog.anotherField": "Add another field",
  "after_field_created.field.dialog.addAnimalGroup": "Add animal group",
  "map.postcodeInput.placeholder": "AB10 1AB",
  "map.postcodeInput.helperText": "Navigate to farm postcode",
  "oauth2.redirect.dialog.title": "Redirecting",
  "oauth2.redirect.dialog.content":
    "You will now be redirected to <a>{url}</a>. ",
  "oauth2.success.dialog.title": "Success!",
  "oauth2.success.dialog.content":
    "Welcome to ruumi! You have connected your {providerName} account. We are loading your farms, fields and animals.",
  "oauth2.success.dialog.contentSignup":
    "Welcome to ruumi! Please tell us about your farm while we are loading your data from {providerName}. You may skip this form if you have previously completed it.",
  "oauth2.success.dialog.importing":
    "Importing farm {farmName}, {animalGroupsCount} animal groups, {fieldsCount} fields imported.",
  "oauth2.error.dialog.title": "Error",
  "oauth2.error.dialog.content":
    "Could not connect {providerName} account, please retry <a>here</a>. ",
  "settings.agriwebb": "AgriWebb connected",
  "settings.agriwebb.remove": "Remove connection",
  "oauth2ClientRemove.success": "{providerName} integration removed",
  "newSignup.userDetails.text1": "Welcome to ruumi!",
  "newSignup.userDetails.text2": "Sign up and start using the grazing app now!",
  "signup.confirmPassword.label": "Confirm password",
  "signup.confirmPassword.error": "Passwords don't match",
  "signup.button.newSignup": "Go to app",
  "signup.signupError":
    "Error occured. This email might be already registered. Please use another one or contact support to restore password.",
  "newSignup.userDetails.alreadyRegistered":
    "Already have an account? <a>Sign in here</a>.",

  "passwordForgot.dialog.title": "Reset Your Password",
  "passwordForgot.mail.label": "Email",
  "passwordForgot.button.label": "Reset password",
  "passwordForgot.success":
    "Great news! We've just sent you an email with instructions on how to reset your password. Please keep an eye on your inbox, as it should arrive shortly.",
  "passwordForgot.success.2":
    "If you still can't find it, please don't hesitate to contact us at hello@ruumi.io and we'll be happy to assist you further.",
  "passwordForgot.failure":
    "Unable to send email to your address. Please reach us at hello@ruumi.io.",

  "passwordReset.userDetails.text1": "Set your new password",
  "passwordReset.userDetails.dialogTitle": "Reset password",
  "passwordReset.button.label": "Reset password",
  "passwordChange.failure": "Unable to change password",
  "defaultStockClass.DAIRY_COW": "Dairy Cow",
  "defaultStockClass.DAIRY_COW_2_KGMS": "Dairy Cow (2 kgMS)",
  "defaultStockClass.DAIRY_COW_2_KGMS.notes":
    "2kgMS = ~28l @ 7.5% solids - peak lactation",
  "defaultStockClass.SUCKLER_COW": "Suckler Cow",
  "defaultStockClass.SUCKLER_COW.notes": "includes calve up to 200 days of age",
  "defaultStockClass.GROWING_CATTLE": "Growing Cattle",
  "defaultStockClass.EWE": "Ewes",
  "defaultStockClass.EWE_TWINS": "Ewes (Twins)",
  "defaultStockClass.EWE_TWINS.notes": "includes lambs up to 100 days of age",
  "defaultStockClass.WEANED_LAMB": "Weaned lamb",

  "defaultFeeding.DRY_MAINTENANCE": "Dry - maintenance",
  "defaultFeeding.THIRTY_DAYS_PRE_CALVING": "30 Days Pre Calving",
  "defaultFeeding.LTE_2_MONTHS_POST_CALVING": "0-2 months Post Calving",
  "defaultFeeding.GT_2_MONTHS_POST_CALVING": ">2 months Post Calving",
  "defaultFeeding.DRY_MAINTENANCE_BCS_GAIN": "Dry - Maintenance + BCS Gain",
  "defaultFeeding.GTE_3_LTE_4_MONTHS_POST_CALVING": "3-4 months Post Calving",
  "defaultFeeding.GT_4_MONTHS_POST_CALVING":
    ">4 Months Post Calving to Weaning",
  "defaultFeeding.GT_4_MONTHS_POST_CALVING.notes":
    "includes calve up to 200 days of age",
  "defaultFeeding.MAINTENANCE": "Maintenance",
  "defaultFeeding.MAINTENANCE_500G_DLWG": "Maintenance + 0.5kg DLWG",
  "defaultFeeding.MAINTENANCE_1KG_DLWG": "Maintenance + 1kg DLWG",
  "defaultFeeding.MAINTENANCE_GT_1KG_DLWG": "Maintenance + >1kg DLWG",
  "defaultFeeding.THIRTY_DAYS_PRE_LAMBING": "30 Days Pre Lambing",
  "defaultFeeding.LTE_1_MONTHS_POST_LAMBING": " 0-1 month Post Lambing",
  "defaultFeeding.GT_1_MONTH_POST_LAMBING_TO_WEANING":
    ">1 Month Post Lambing to Weaning",
  "defaultFeeding.GT_1_MONTH_POST_LAMBING_TO_WEANING.notes":
    " includes lambs up to 100 days of age",
  "defaultFeeding.MAINTENANCE_50G_DLWG": " Maintenance + 50g DLWG",
  "defaultFeeding.MAINTENANCE_150G_DLWG": " Maintenance + 150g DLWG",
  "defaultFeeding.MAINTENANCE_GT_150G_DLWG": "Maintenance + >150g DLWG",
  "stockClassDemandTemplateAutocomplete.customValue": 'Use "{inputValue}"',
  "stockClassDemandTemplateAutocomplete.listItemTitle":
    "{stockClassName} — {allocatedBodyWeightFraction}% BW",
  "common.reloadPage":
    "The grazing app has been updated, loading new version, please wait",
  "moveOut.updateCover": "Update cover on {name}?",
  "moveOut.fromField": "From Field: {fieldName}",
  "moveOut.toField": "To Field: {fieldName}",
  "moveOut.title": "Animal is moving",
  "moveOut.date.label": "Date",
  "moveOut.minDateInfo":
    "This animal group was last moved on {minDate}. Therefore this animal move must be after the above date.",
  "moveOut.subTitle":
    "Update biomass on {fieldName} from {currentCover}kgDM/ha",
  "moveOut.subTitle2": "to",
  "moveOut.postCover.label": "Updated cover",
  "moveOut.leaveIt.label": "Don't update biomass",
  "moveOut.changeIt.label": "Update biomass",
  "feedBudgetPlanner.noFields.prompt":
    "Assign at least one field to this animal group, to see the grass supply",
  "feedbBudgetPlanner.feedAllocation.allocatedBodyWeightFractionPercent":
    "Allocated BW%: {allocatedBodyWeightFractionPercent}%",
  "feedbBudgetPlanner.feedAllocation.allocatedBodyWeightFractionKg":
    "Allocated kg: {allocatedBodyWeightFractionKg}",
  "feedbBudgetPlanner.feedAllocation.allocationType.OFFSPRING": "Offspring",
  "feedbBudgetPlanner.feedAllocation.allocationType.STOCK_CLASS": "Stock class",
  "feedbBudgetPlanner.feedAllocation.stockClassFeedAllocationLineNameBW":
    "Daily allocation for {stockClassName} per animal (BW%)",
  "feedbBudgetPlanner.feedAllocation.stockClassFeedAllocationLineNameKg":
    "Daily allocation for {stockClassName} per animal (kg)",
  "feedbBudgetPlanner.feedAllocation.totalDemandPerAnimalGroup.title":
    "Total daily demand for {animalGroupName} (kg/ha/day)",
  "feedbBudgetPlanner.feedAllocation.totalDemandPerAnimalGroupTooltip":
    "Daily demand: {demandKgHaDay} kg/ha/day",
  "feedBudgetPlanner.feedAllocation.totalSupply.title":
    "Daily supply (kg/ha/day)",
  "feedBudgetPlanner.feedAllocation.totalSupply.tooltip":
    "Daily supply {supply} (kg/ha/day)",
  "feedBudgetPlanner.feedAllocation.totalProjectedSupply.title":
    "Projected daily supply (kg/ha/day)",
  "feedBudgetPlanner.feedAllocation.totalDemand.title":
    "Daily demand (kg/ha/day)",
  "feedBudgetPlanner.feedAllocation.totalDemand.tooltip":
    "Daily demand {demand} (kg/ha/day)",
  "feedBudgetPlanner.feedAllocation.fieldTransfer.title":
    "Assign fields to {name}",
  "feedBudgetPlanner.feedAllocation.fieldTransfer.confirmButton.title":
    "Confirm",
  "feedBudgetPlanner.feedAllocation.fieldTransfer.cancelButton.title": "Cancel",
  "feedBudgetPlanner.feedAllocation.fieldTransfer.silageFields":
    "Silage fields",
  "feedBudgetPlanner.feedAllocation.fieldTransfer.animalGroupFields":
    "Fields assigned to {name}",
  "feedBudgetPlanner.title": "Feed budget for {name}",
  "feedBudgetPlanner.animalGroupChart.title":
    "{name} daily demand & supply (kg/ha/day for the whole group)",
  "feedBudgetPlanner.stockClassDemandChart.title":
    "{name} daily demand (kg / day per animal)",
  "feedBudgetPlanner.stockClassFeedAllocationChart.kg": "kg",
  "feedBudgetPlanner.stockClassFeedAllocationChart.bw": "BW%",
  "feedBudgetPlanner.helpDialog.title": "ruumi feeding guidelines",
  "feedBudgetPlanner.helpDialog.feeding": "Feeding",
  "feedBudgetPlanner.helpDialog.bw": "%BW",
  "feedBudgetPlanner.helpDialog.kg": "kgDm/day",
  "animalGroupInput.feedAllocation.create.success":
    "{animalGroupName} created, default feed allocation generated",
  "animalGroupInput.feedAllocation.edit.success":
    "{animalGroupName} updated, feed allocation regenerated",
  "animalGroupInput.regenerateFeedAllocationDialog.text":
    "Default feed budget would be computed, some manual changes to feed allocations related to the birthing periods would be lost. Do you want to proceed?",
  "animalGroupInput.regenerateFeedAllocationDialog.title": "Discard Edits",
  "animalGroupInput.regenerateFeedAllocationDialog.confirm.label": "Confirm",
  "animalGroupInput.regenerateFeedAllocationDialog.cancel.label":
    "Keep editing",
  "feedBudgetPlanner.targetEntryCoverDialog.title":
    "Target entry cover for {name}",
  "feedBudgetPlanner.targetEntryCoverDialog.table.description":
    "For this stock class recommended target entry cover for high performance is as follows:",
  "feedBudgetPlanner.targetEntryCoverDialog.table.header.kgdmha": "kgDM/ha",
  "feedBudgetPlanner.targetEntryCoverDialog.table.header.averageWeight":
    "Average weight (kg)",
  "feedBudgetPlanner.targetEntryCoverDialog.table.header.min": "Min",
  "feedBudgetPlanner.targetEntryCoverDialog.table.header.optimum": "Optimum",
  "feedBudgetPlanner.targetEntryCoverDialog.table.header.max": "Max",
  "feedBudgetPlanner.targetEntryCoverDialog.input.description":
    "Target entry cover for {name} currently set at:",
  "feedBudgetPlanner.referenceArea.birthingPeriod.label":
    "Lambing/Calving {start} - {end}",
  "feedBudgetPlanner.referenceLabel.now.label": "Today",
  "farmView.button.grasstype-reseeding.title": "Grass type & Re-seeding",
  "reseedingDetailView.button.addReseedingEvent.title":
    "Add new reseeding event",
  "reseedingDetailView.button.updateGrassTypes.title": "Update grass types",
  "reseedEvent.field": "{name}",
  "reseedEvent.note": "Note: {note}",
  "reseedEvent.seedingMethod": "Method: {seedingMethod}",
  "reseedEvent.to": "To: {newGrassType}",
  "reseedEvent.list.title": "Previous Events",
  "reseedEvent.menu.editView": "Edit/View",
  "reseedEvent.seedingMethod.option.PLOUGH_AND_POWER_HARROW":
    "Plough and Power Harrow",
  "reseedEvent.seedingMethod.option.DIRECT_DRILL": "Direct Drill",
  "reseedEvent.seedingMethod.option.SHALLOW_CULTIVATION": "Shallow Cultivation",
  "reseedEvent.seedingMethod.option.OVERSEED": "Overseed",
  "reseedEvent.seedingMethod.text": "Seeding method",
  "diaryEntry.diaryEntryText.label": "Entry text: {diaryEntryText}",
  "diaryEntry.show.titleName": "Diary entry",
  "diaryEntry.entryDate.label": "Date: {diaryEntryDate}",
  "diaryEntry.showImage.title": "Diary entry from {diaryEntryDate}",
  "diaryEntry.delete.label": "Delete",
  "diaryEntry.delete.confirm.label": "Yes",
  "diaryEntry.cancel.label": "Cancel",
  "diaryEntry.areYouSure.label": "Are you sure you want to delete this photo?",
  "diaryEntry.view.delete.menu": "View/Delete",
  "diaryEntry.view.menu": "View",
  "diaryEntry.view.close.label": "Close",
  "diaryEntry.deleted.notification": "Successfully deleted diary entry",
  "diaryEntry.delete.tooltip": "Delete diary entry",
  "go.to.landmanagement.and.grazing.button": "Land Management & Grazing",
  "go.to.inputs.and.outputs": "Inputs & Outputs",
  "toolbar.inputs.and.outputs.view": "Inputs & Outputs",
  "inputs.outputs.reseeding.title": "Re-seeding",
  "home.button.title": "Home",
  "toolbar.feed-overview.title": "Feed",
  "inputs.outputs.feed.purchase.title": "Feed",
  "feedPurchaseDetailView.button.addFeedPurchase.title":
    "Add new Feed Purchase event",
  "feedPurchase.list.title": "Previous Events",
  "purchased.show.titleName": "Feed purchase: {name}",
  "feedPurchaseEvent.feedPurchaseDate": "Date: {feedPurchasedDate}",
  "feedPurchaseEvent.feedType.text": "Type of Feed",
  "feedPurchase.feedType.option.HAYLAGE": "Haylage",
  "feedPurchase.feedType.option.SILAGE": "Silage",
  "feedPurchase.feedType.option.GRAIN": "Grain",
  "feedPurchase.feedType.option.CONCENTRATED_FEED": "Concentrated Feed",
  "feedPurchase.feedType.option.HAY": "Hay",
  "feedPurchaseEvent.cost.label": "Cost (in GBP)",
  "feedPurchase.feedUnit.option.KG": "Kg",
  "feedPurchase.feedUnit.option.TONNE": "Tonne",
  "feedPurchaseEvent.feedUnit.text": "Unit type",
  "feed.stock.summary.header": "Feed In Store",
  "feedPurchaseEvent.menu.editView": "Edit",
  "feedStock.summary.noFeedAvailable": "No feed available",
  "feedPurchaseEvent.feedAmount.label": "Amount",
  "enterFeedPurchase.heading": "Feed purchase",
  "feedEventInput.removeFeed": "delete",
  "feedEventInput.addFeed": "Add new Feed type",
  "feedEventInput.title": "Feed Event",
  "feedEventInput.note": "Note",
  "feedEventDetailView.button.addFeedEvent.title": "Add new Feed event",
  "feedEventInput.AnimalGroups.text": "Animal Groups",
  "notEnoughFeed.cancel.label": "got it",
  "notEnoughFeed.title": "Not enough feed available!",
  "addFeedEvent.add.success": "Added a new feed event.",
  "reatefeedPurchaseEvent.success": "Added a new feed purchase event.",
  "feedItem.animalGroups.fedTo": "Fed to: ",
  "yieldEvent.yieldType.option.HAYLAGE": "Haylage",
  "yieldEvent.yieldType.option.SILAGE": "Silage",
  "yieldEvent.yieldType.option.HAY": "Hay",
  "yieldEvent.yieldType.option.STRAW": "Straw",
  "yieldEvent.yieldType.option.WHEAT": "Wheat",
  "yieldEvent.yieldType.option.BARLEY": "Barley",
  "yieldEvent.yieldType.option.OILSEED_RAPE": "Oilseed rape",
  "yieldEvent.yieldType.option.POTATO": "Potato",
  "yieldEvent.yieldType.option.MAIZ": "Maiz",
  "yieldEvent.yieldType.option.CEREALS": "Cereals",
  "yieldEvent.yieldType.option.VEGETABLES_SALAD": "Vegetables/Salad",
  "yieldEvent.yieldType.option.OTHERS": "Other",
  "yieldEvent.yieldType.option.MEAT": "Meat",
  "yieldEvent.yieldType.option.MILK": "Milk",
  "yieldEvent.yieldType.option.GRASS": "Grass",
  "yieldEvent.yieldType.option.CROP": "Crop",
  "yieldEventInput.title": "Enter yields",
  "yieldEventInput.addYield": "Add another yield",
  "yieldEvent.yieldAmount.label": "Amount",
  "yieldEvent.yieldType.text": "yieldType",
  "inputs.outputs.yield.title": "Yields & Harvests",
  "toolbar.yield-overview.title": "Yield",
  "yieldEventDetailView.button.addYieldEvent.title": "Add new Yield Event",
  "yieldEventInput.removeYield": "Remove yield",
  "yieldEvent.yieldType.MEAT": "Meat",
  "yieldEvent.yieldType.MILK": "Milk",
  "yieldEvent.yieldType.CROP": "Crop",
  "yieldEvent.yieldType.GRASS": "Grass",
  "yieldEvent.soldOrKept.label.SOLD": "Sold",
  "yieldEvent.soldOrKept.label.KEPT": "Kept",
  "yieldEvent.unitType.option.TONNE": "Tonne",
  "yieldEvent.unitType.option.KG": "Kg",
  "yieldEvent.unitType.option.LITRE": "Litre",
  "yieldEvent.unitType.option.M3": "M3",
  "yieldEvent.unitType.text": "Unit type",
  "yieldEvent.revenue.label": "Amount (in GBP)",
  "yieldEvent.soldOrKept.text": "Sold/Keep",
  "yieldEvent.fuel.label": "Fuel (in Litre)",
  "yieldEvent.machinery.label": "Machinery",
  "yieldEventInput.grassFields.text": "Grass fields",
  "yieldEventInput.animalGroups.text": "Animal Groups",
  "yieldEventInput.arableFields.text": "Arable Fields",
  "yieldEvent.yieldEventDate": "Date: {yieldEventDate}",
  "yieldEvent.grassFields.harvestedFrom": "Fields: ",
  "yieldEvent.arableFields.harvestedFrom": "Fields: ",
  "yieldEvent.animalGroups.harvestedFrom": "Animal groups: ",
  "yieldEvent.amount.sold": "{amount} sold for {soldPrice}",
  "yieldEvent.amount.kept": "{amount} kept",
  "yieldEventInput.machinery.options.SPRAYER": "Sprayer",
  "yieldEventInput.machinery.options.MOWER": "Mower",
  "yieldEventInput.machinery.options.SEED_BROADCASTER": "Seed broadcaster",
  "yieldEventInput.machinery.options.DIRECT_DRILL": "Direct drill",
  "yieldEventInput.machinery.options.MANURE_SPREADER": "Manure Spreader",
  "yieldEventInput.machinery.options.SLURRY_SPREADER": "Slurry Spreader",
  "yieldEventInput.machinery.options.BALER": "Baler",
  "yieldEvent.menu.editView": "Edit",
  "yieldEventInput.machinery.label": "Machinery",
  "addYieldEvent.add.success": "Added new yield event",
  "yield.stock.summary.header": "Yields & Harvests In Store",
  "inputs.outputs.fertlizer.title": "Fertiliser",
  "fertilizerEventInput.title": "Fertiliser",
  "fertilizerEvent.fertilizerType.label.ORGANIC": "Organic",
  "fertilizerEvent.fertilizerType.label.SYNTHETIC": "Synthetic",
  "fertilizerEvent.fertilizerType.label.LIME": "Lime",
  "fertilizerEvent.fertilizerType.label.BIOSOLIDS": "Biosolids",
  "fertilizerEvent.fertilizerType.label.BIODIGESTATE": "Biodigestate",
  "fertilizerEvent.fertilizerType.label.COMPOST": "Compost",
  "fertilizerEvent.fertilizerType.label.OTHER": "Other",
  "fertilizerEvent.organicFertilizerType.label.FYM": "FYM",
  "fertilizerEvent.organicFertilizerType.label.SLURRY": "Slurry",
  "fertilizerEvent.organicFertilizerType.label.DIRTY_WATER": "Dirty Water",
  "fertilizerEventInput.animalType.options.DAIRY_CATTLE": "Dairy Cattle",
  "fertilizerEventInput.animalType.options.BEEF_CATTLE": "Beef Cattle",
  "fertilizerEventInput.animalType.options.PIG": "Pig",
  "fertilizerEventInput.animalType.options.SHEEP": "Sheep",
  "fertilizerEventInput.animalType.options.HORSE": "Horse",
  "fertilizerEventInput.animalType.options.CHICKEN_LITTER": "Chicken Litter",
  "fertilizerEvent.fertilizerProperties.applicationMethod.options.DISC_INJECTOR":
    "Disc Injector",
  "fertilizerEvent.fertilizerProperties.applicationMethod.options.TRAILING_SHOE":
    "Trailing Shoe",
  "fertilizerEvent.fertilizerProperties.applicationMethod.options.DRIBBLE_BAR":
    "Dribble Bar",
  "fertilizerEvent.fertilizerProperties.applicationMethod.options.SPLASH_PLATE":
    "Splash Plate",
  "fertilizerEvent.fertilizerProperties.applicationMethod.options.OTHER":
    "Other",
  "fertilizerProperties.applicationMethod": "Application method",
  "fertilizerEvent.fertilizerProperties.organicType.text":
    "Organic Fertiliser Type",
  "fertilizerEventInput.animalType.label": "Animal Type",
  "fertilizerEventInput.machinery.label": "Machinery",
  "fertilizerEvent.fuel.label": "Fuel (in Litre)",
  "fertilizerEventInput.fields.text": "Field(s)",
  "fertilizerEventDetailView.button.addFertilizerEvent.title":
    "Add fertilizer event",
  "fertilizerInput.fertilizerType.label": "Type of Fertiliser",
  "organicFertilizer.imported.label": "Is imported?",
  "fertilizerEvent.totalAmount.label": "Total Amount",
  "fertilizerInput.totalAmountUnit.label": "Total Amount Unit",
  "fertilizerEvent.totalAmountUnit.option.KG": "kg",
  "fertilizerEvent.totalAmountUnit.option.TONNE": "tonne",
  "fertilizerEvent.totalAmountUnit.option.M3": "m3",
  "fertilizerEvent.totalAmountUnit.option.GALLONS": "gallons",
  "fertilizerEvent.field.name": "field:",
  "fertilizerEvent.fertilizerEventDate": "{fertilizerEventDate}",
  "fertilizerEvent.typeOfNitrogen.label.UREA": "Urea",
  "fertilizerEvent.typeOfNitrogen.label.PROTECTED_UREA": "Protected Urea",
  "fertilizerEvent.typeOfNitrogen.label.UAN": "UAN",
  "fertilizerEvent.typeOfNitrogen.label.CAN": "CAN",
  "fertilizerEvent.typeOfNitrogen.label.CN": "CN",
  "fertilizerEvent.typeOfNitrogen.label.AN": "AN",
  "fertilizerEvent.typeOfNitrogen.label.OTHER": "Other",
  "fertilizerEvent.fertilizerProperties.kPercentage.label": "%K",
  "fertilizerEvent.fertilizerProperties.pPercentage.label": "%P",
  "fertilizerEvent.fertilizerProperties.nPercentage.label": "%N",
  "fertilizerEvent.fertilizerProperties.productName.label": "Product name",
  "fertilizerEvent.fertilizerProperties.typeOfNitrogen.text":
    "Type of Nitrogen",
  "fertilizerEvent.fertilizerProperties.phPriorToApplication.label":
    "PH prior to application",
  "toolbar.fertilizer-overview.title": "Fertiliser",
  "updateFertilizerEvent.add.success": "Sucessfully updated fertiliser event.",
  "addFertilizerEvent.add.success": "Sucessfully added fertiliser event.",
  "reseedEvent.machinery.label": "Machinery",
  "reseedEvent.reseedType.OVERSEED": "Overseed",
  "reseedEvent.reseedType.RESEED": "Reseed",
  "reseedEvent.productName.label": "Seed product name",
  "reseedEvent.seedingRate.label": "Seed rate (kg/ha)",
  "reseedEvents.RESEED.explanation":
    "Re-seeding introduces new seeds to bare or worn areas, changing the grass type, such as from rye grass to a herbal ley mix.",
  "reseedEvents.OVERSEED.explanation":
    "Overseeding adds the same seeds to a field to reduce bare patches or improve germination of specific seeds, while maintaining the existing seed type.",
  "reseedEvent.totalAmountUnit.label": "Unit Type",
  "reseedEvent.totalAmountUnit.label.G": "g",
  "reseedEvent.totalAmountUnit.label.KG": "kg",
  "reseedEvent.totalAmountUnit.label.TONNE": "tonne",
  "reseedEvent.totalAmount.label": "Total Amount",
  "toolbar.field-preparation-overview.title": "Field Preparation",
  "fieldPreparationEvent.fields.name": "Fields",
  "fieldPreparationEventDetailView.button.addFieldPreparationEvent.title":
    "Add new Field Prep event",
  "inputs.outputs.fieldPreparation.title": "Field Preparation",
  "fieldPreparationEvent.fieldPreparationEventDate":
    "{fieldPreparationEventDate}",
  "fieldPreparationEventInput.title": "Field Preparation Event",
  "fieldPreparationEventInput.fields.text": "Field(s)",
  "fieldPreparationEvent.fuel.label": "Fuel",
  "fieldPreparationEventInput.machinery.label": "Machinery",
  "updateFieldPreparationEvent.add.success": "Updated field preparation event",
  "addFieldPreparationEvent.add.success": "Added field preparation event",
  "fieldPreparationEvent.machinery.name": "Machinery",
  "fieldPreparationEvent.fuel.name": "Fuel",
  "fieldPreparationEvent.note.name": "Note",
  "fertilizer.amount.cost.heading": "Fertiliser amount & cost",
  "fertilizer.operational.cost.heading": "Operation cost",
  "fertilizerEvent.operationalCost.label": "Cost (in GBP)",
  "fertilizerEvent.productCost.label": "Cost (in GBP)",
  "reseed.amount.cost.heading": "Reseeding amount & cost",
  "reseed.operational.cost.heading": "Operation cost",
};
