import { useState } from "react";
import styled, { css } from "styled-components";
import {
  Button,
  Container,
  Divider,
  LinearProgress,
  TextField,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import {
  useAppSelector,
  YieldEvent,
  selectAllAnimalGroups,
  AnimalGroup,
  YIELD_TYPES,
  MACHINERY,
  SOLD_OR_KEPT,
  GRASS_YIELD_TYPES,
  CROP_YIELD_TYPE,
  MILK_UNITS,
  MEAT_UNITS,
  GRASS_UNITS,
  CROP_UNITS,
  GrassYieldAmount,
  CropYieldAmount,
  Field,
  selectAllFields,
  ArableField,
  selectAllArableFields,
  GrassYieldDetails,
  MilkYieldDetails,
  MeatYieldDetails,
  CropYieldDetails,
  MeatYieldAmount,
  MilkYieldAmount,
  REVENUE_CURRENCY,
  PURCHASE_CURRENCY,
} from "model";
import { useTranslation } from "common/locales";
import { useSelector } from "react-redux";
import { FieldArray, Form, Formik, FormikErrors } from "formik";

import { cloneDeep } from "lodash";
import * as Yup from "yup";
import EnterYieldEventYieldDetails from "./EnterYieldEventYieldDetails";
import EnterDate from "features/general/EnterDate";
import { mixpanel } from "common/analytics/mixpanel";
import ConfirmCancellationDialog from "features/general/ConfirmCancellationDialog";
import { ButtonContainer, ButtonGroup } from "./generalMethods";
import YieldTypeRadioButtons from "./YieldTypeRadioButtons";
import NameAndGuidMultiSelectDropdown from "features/general/NameAndGuidMultiSelectDropdown";
import CropTypeMissingDialog from "./CropTypeMissingDialog";

interface Props {
  yieldEvent: YieldEvent | undefined;
  handleSave: (yieldInput: YieldEvent) => void;
  handleClose: () => void;
}

export type YieldDetailsFormData = {
  amount?: number;
  soldOrKept?: SOLD_OR_KEPT;
  detailYieldType?:
    | GRASS_YIELD_TYPES
    | CROP_YIELD_TYPE
    | YIELD_TYPES.MEAT
    | YIELD_TYPES.MILK;
  unitType?: MILK_UNITS | MEAT_UNITS | GRASS_UNITS | CROP_UNITS;
  revenue?: number;
};
export type YieldEventFormData = {
  note?: string;
  machinery?: MACHINERY[];
  fuel?: number;
  operationalCost?: number;
  yieldEventDate: Date;
  yieldType?: YIELD_TYPES;
  fieldOrAnimalGroupGuids: string[];
  yieldAmounts: YieldDetailsFormData[];
};

const convertToFormInput = (yieldEvent: YieldEvent): YieldEventFormData => {
  return {
    note: yieldEvent.note,
    fuel: yieldEvent.fuel,
    operationalCost: yieldEvent.operationalCost,
    machinery: yieldEvent.machinery,
    yieldEventDate: new Date(yieldEvent.yieldEventDate),
    yieldType: yieldEvent.yieldDetails.yieldType,
    fieldOrAnimalGroupGuids:
      yieldEvent.yieldDetails.yieldType === YIELD_TYPES.GRASS ||
      yieldEvent.yieldDetails.yieldType === YIELD_TYPES.CROP
        ? yieldEvent.yieldDetails.fieldGuids
        : yieldEvent.yieldDetails.animalGroupGuids,
    yieldAmounts: yieldEvent.yieldDetails.yieldAmounts.map((yieldDetail) => {
      let detailYieldType:
        | GRASS_YIELD_TYPES
        | CROP_YIELD_TYPE
        | YIELD_TYPES.MEAT
        | YIELD_TYPES.MILK;
      if (yieldEvent.yieldDetails.yieldType === YIELD_TYPES.GRASS) {
        detailYieldType = (yieldDetail as GrassYieldAmount).grassYieldType;
      } else if (yieldEvent.yieldDetails.yieldType === YIELD_TYPES.CROP) {
        detailYieldType = (yieldDetail as CropYieldAmount).cropYieldType;
      } else {
        detailYieldType = yieldEvent.yieldDetails.yieldType;
      }

      return {
        amount: yieldDetail.amount,
        soldOrKept: yieldDetail.soldOrKept,
        detailYieldType,
        unitType: yieldDetail.unitType,
        revenue: yieldDetail.revenue,
      };
    }),
  };
};

const getYieldAmounts = (
  yieldEventFormData: YieldEventFormData
):
  | GrassYieldAmount[]
  | MeatYieldAmount[]
  | MilkYieldAmount[]
  | CropYieldAmount[] => {
  const resultArray = yieldEventFormData.yieldAmounts.map(
    (yieldDetails: YieldDetailsFormData) => {
      const revenue =
        yieldDetails.soldOrKept === SOLD_OR_KEPT.KEPT
          ? undefined
          : (yieldDetails.revenue as unknown as string) === ""
          ? undefined
          : yieldDetails.revenue;
      const result: { [key: string]: string | number | undefined } = {};
      result["unitType"] = yieldDetails.unitType;
      result["amount"] = yieldDetails.amount;
      result["soldOrKept"] = yieldDetails.soldOrKept;
      result["revenue"] = revenue;
      result["revenueCurrency"] = revenue ? REVENUE_CURRENCY.GBP : undefined;
      if (yieldEventFormData.yieldType === YIELD_TYPES.GRASS) {
        result["grassYieldType"] = yieldDetails.detailYieldType;
      }
      if (yieldEventFormData.yieldType === YIELD_TYPES.CROP) {
        result["cropYieldType"] = yieldDetails.detailYieldType;
      }
      return result;
    }
  );
  if (yieldEventFormData.yieldType === YIELD_TYPES.GRASS) {
    return resultArray as GrassYieldAmount[];
  }
  if (yieldEventFormData.yieldType === YIELD_TYPES.CROP) {
    return resultArray as CropYieldAmount[];
  }
  if (yieldEventFormData.yieldType === YIELD_TYPES.MILK) {
    return resultArray as MilkYieldAmount[];
  }
  return resultArray as MeatYieldAmount[];
};

const getYieldDetails = (
  yieldEventFormData: YieldEventFormData
):
  | GrassYieldDetails
  | MilkYieldDetails
  | MeatYieldDetails
  | CropYieldDetails => {
  if (yieldEventFormData.yieldType === YIELD_TYPES.GRASS) {
    return {
      yieldType: YIELD_TYPES.GRASS,
      yieldAmounts: getYieldAmounts(yieldEventFormData) as GrassYieldAmount[],
      fieldGuids: yieldEventFormData.fieldOrAnimalGroupGuids,
    };
  }
  if (yieldEventFormData.yieldType === YIELD_TYPES.CROP) {
    return {
      yieldType: YIELD_TYPES.CROP,
      yieldAmounts: getYieldAmounts(yieldEventFormData) as CropYieldAmount[],
      fieldGuids: yieldEventFormData.fieldOrAnimalGroupGuids,
    };
  }
  if (yieldEventFormData.yieldType === YIELD_TYPES.MEAT) {
    return {
      yieldType: YIELD_TYPES.MEAT,
      yieldAmounts: getYieldAmounts(yieldEventFormData) as MeatYieldAmount[],
      animalGroupGuids: yieldEventFormData.fieldOrAnimalGroupGuids,
    };
  }
  return {
    yieldType: YIELD_TYPES.MILK,
    yieldAmounts: getYieldAmounts(yieldEventFormData) as MilkYieldAmount[],
    animalGroupGuids: yieldEventFormData.fieldOrAnimalGroupGuids,
  };
};

const convertFromFormInput = (
  yieldEventFormData: YieldEventFormData
): YieldEvent => {
  const operationalCost =
    (yieldEventFormData.operationalCost as unknown as string) === ""
      ? undefined
      : yieldEventFormData.operationalCost;
  return {
    note: yieldEventFormData.note,
    machinery: yieldEventFormData.machinery,
    fuel:
      (yieldEventFormData.fuel as unknown as string) === ""
        ? undefined
        : yieldEventFormData.fuel,
    operationalCost,
    operationalCostUnit: operationalCost ? PURCHASE_CURRENCY.GBP : undefined,
    yieldDetails: getYieldDetails(yieldEventFormData),
    yieldEventDate: new Date(yieldEventFormData.yieldEventDate).toISOString(),
    guid: " ",
  };
};

export default function EnterYieldEvent({
  yieldEvent,
  handleSave,
  handleClose,
}: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const loading = useAppSelector((state) => state.animalGroups.loading);

  const allAnimalGroups: AnimalGroup[] = useSelector(selectAllAnimalGroups);

  const allGrassFields: Field[] = useSelector(selectAllFields);

  const allArableFields: ArableField[] = useSelector(selectAllArableFields);

  const [missingCropTypesDialogOpen, setMissingCropTypesDialogOpen] =
    useState(false);

  const checkCropMissing = (
    arableFields: ArableField[],
    crops: YieldDetailsFormData[] | undefined
  ) => {
    if (!crops) {
      return false;
    }
    const cropTypes = crops?.map((crop) => crop.detailYieldType);
    for (const arableField of arableFields) {
      if (!cropTypes.includes(arableField.arableType)) {
        setMissingCropTypesDialogOpen(true);
        return false;
      }
    }
    return true;
  };

  const validNumber = (min = 0) =>
    Yup.number()
      .typeError(t("edit.minNumber.error", { min }))
      .moreThan(min, t("edit.minNumber.error", { min }))
      .required(t("edit.required.error"));

  const YieldEventSchema = Yup.object()
    .shape({
      note: Yup.string().optional(),
      machinery: Yup.array()
        .of(Yup.mixed<MACHINERY>().oneOf(Object.values(MACHINERY)))
        .optional(),
      fuel: Yup.lazy((value) =>
        value === "" ? Yup.string() : Yup.number().min(0)
      ).optional(),
      operationalCost: Yup.lazy((value) =>
        value === "" ? Yup.string() : Yup.number().min(0)
      ).optional(),
      yieldEventDate: Yup.date(),
      yieldType: Yup.mixed<YIELD_TYPES>()
        .oneOf(Object.values(YIELD_TYPES))
        .required(),
      fieldOrAnimalGroupGuids: Yup.array().of(Yup.string()).required().min(1),
      yieldAmounts: Yup.array()
        .of(
          Yup.object().shape({
            amount: validNumber(0).required(),
            soldOrKept: Yup.mixed<SOLD_OR_KEPT>()
              .oneOf(Object.values(SOLD_OR_KEPT))
              .required(),
            detailYieldType: Yup.mixed<
              GRASS_YIELD_TYPES | CROP_YIELD_TYPE | YIELD_TYPES
            >()
              .oneOf([
                ...Object.values(GRASS_YIELD_TYPES),
                ...Object.values(CROP_YIELD_TYPE),
                YIELD_TYPES.MEAT,
                YIELD_TYPES.MILK,
              ])
              .required(),
            unitType: Yup.mixed<
              MILK_UNITS | MEAT_UNITS | GRASS_UNITS | CROP_UNITS
            >()
              .oneOf([
                ...Object.values(MILK_UNITS),
                ...Object.values(MEAT_UNITS),
                ...Object.values(GRASS_UNITS),
                ...Object.values(CROP_UNITS),
              ])
              .required(),
            revenue: validNumber(0).optional(),
          })
        )
        .required()
        .min(1),
    })
    .test(
      "crops-missing",
      "Crops missing in crop list",
      (yieldEventFormData: any | undefined) => {
        const selectedArableFields = allArableFields.filter(
          (arableField: ArableField) =>
            yieldEventFormData?.fieldOrAnimalGroupGuids.includes(
              arableField.guid
            )
        );
        return checkCropMissing(
          selectedArableFields,
          yieldEventFormData?.yieldAmounts as YieldDetailsFormData[] | undefined
        );
      }
    );

  const getPossibleCropYields = (arableFieldGuids: string[]) => {
    const selectedArableFields = allArableFields.filter(
      (arableField: ArableField) => arableFieldGuids.includes(arableField.guid)
    );
    const allowedCropTypes = selectedArableFields.map(
      (arableField: ArableField) => arableField.arableType as CROP_YIELD_TYPE
    );

    const potentialStrawCrops: CROP_YIELD_TYPE[] = [
      CROP_YIELD_TYPE.BARLEY,
      CROP_YIELD_TYPE.MAIZ,
      CROP_YIELD_TYPE.WHEAT,
      CROP_YIELD_TYPE.CEREALS,
      CROP_YIELD_TYPE.OILSEED_RAPE,
    ];
    for (const crop of allowedCropTypes) {
      if (potentialStrawCrops.includes(crop)) {
        allowedCropTypes.push(CROP_YIELD_TYPE.STRAW);
        break;
      }
    }
    const result: { [key: string]: string } = {};
    for (const crop of [...new Set(allowedCropTypes)]) {
      result[crop] = CROP_YIELD_TYPE[crop];
    }
    return result;
  };

  const newYieldAmount = {
    unitType: "" as GRASS_UNITS,
    amount: "" as unknown as number,
    soldOrKept: "" as SOLD_OR_KEPT,
    revenue: "" as unknown as number,
    detailYieldType: "" as
      | GRASS_YIELD_TYPES
      | CROP_YIELD_TYPE
      | YIELD_TYPES.MILK
      | YIELD_TYPES.MEAT,
  };

  if (loading) {
    return <LinearProgress />;
  }

  const initialYieldEvent = yieldEvent
    ? convertToFormInput(yieldEvent)
    : {
        note: "",
        yieldEventDate: new Date(),
        yieldType: YIELD_TYPES.GRASS,
        yieldAmounts: [newYieldAmount],
        fieldOrAnimalGroupGuids: [],
        fuel: "" as unknown as number,
        machinery: [],
      };
  return (
    <>
      <Formik
        enableReinitialize={false}
        initialValues={initialYieldEvent}
        validationSchema={YieldEventSchema}
        validateOnChange={false} // disable validation every field change, otherwise would validate all fields
        validateOnBlur={false} // disable validation every field blur, otherwise would validate all fields
        onSubmit={(yieldEventFormData: YieldEventFormData) => {
          const selectedArableFields = allArableFields.filter(
            (arableField: ArableField) =>
              yieldEventFormData?.fieldOrAnimalGroupGuids.includes(
                arableField.guid
              )
          );
          const enoughFeedAvailable = checkCropMissing(
            selectedArableFields,
            yieldEventFormData?.yieldAmounts
          );
          if (!enoughFeedAvailable) {
            return;
          } else {
            void handleSave({
              ...convertFromFormInput(yieldEventFormData),
              guid: yieldEvent?.guid ?? "",
            });
          }
        }}
      >
        {({
          values,
          handleChange,
          errors,
          validateField,
          setFieldValue,
          resetForm,
        }) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const handleInputChange = async (e: any, fieldName: string) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            await (handleChange(e) as any); // async, typings are wrong
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            await (validateField(fieldName) as any); // validate only changed field, async, typings are wrong
          };

          return (
            <>
              <Container style={{ textAlign: "left", marginBottom: 40 }}>
                <Typography variant="h6">
                  {t("yieldEventInput.title")}
                </Typography>
              </Container>

              <Container style={{ textAlign: "left", marginBottom: 40 }}>
                <Grid container display="flex" alignItems="baseline">
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    alignItems="baseline"
                  >
                    <EnterDate
                      disabled={false}
                      date={values.yieldEventDate}
                      setDate={async (date: Date | null) => {
                        await setFieldValue("yieldEventDate", date, false);
                      }}
                      dateError={!!errors.yieldEventDate}
                      name={"yieldEventDate"}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    alignItems="baseline"
                  >
                    {(values.yieldType === YIELD_TYPES.MEAT ||
                      values.yieldType === YIELD_TYPES.MILK) && (
                      <NameAndGuidMultiSelectDropdown
                        required={true}
                        selectedItems={allAnimalGroups
                          .filter((animalGroup: AnimalGroup) => {
                            return values.fieldOrAnimalGroupGuids.includes(
                              animalGroup.guid
                            );
                          })
                          .map((animalGroup: AnimalGroup) => {
                            return {
                              guid: animalGroup.guid,
                              name: animalGroup.name,
                            };
                          })}
                        items={allAnimalGroups.map(
                          (animalGroup: AnimalGroup) => {
                            return {
                              name: animalGroup.name,
                              guid: animalGroup.guid,
                            };
                          }
                        )}
                        disabled={false}
                        onChange={(e) =>
                          handleInputChange(e, "fieldOrAnimalGroupGuids")
                        }
                        selectInputError={!!errors.fieldOrAnimalGroupGuids}
                        label={t("yieldEventInput.animalGroups.text")}
                        name={"fieldOrAnimalGroupGuids"}
                      />
                    )}

                    {values.yieldType === YIELD_TYPES.GRASS && (
                      <NameAndGuidMultiSelectDropdown
                        required={true}
                        selectedItems={allGrassFields
                          .filter((grassField: Field) => {
                            return values.fieldOrAnimalGroupGuids.includes(
                              grassField.guid
                            );
                          })
                          .map((grassField: Field) => {
                            return {
                              guid: grassField.guid,
                              name: grassField.name,
                            };
                          })}
                        items={allGrassFields.map((grasField: Field) => {
                          return {
                            name: grasField.name,
                            guid: grasField.guid,
                          };
                        })}
                        disabled={false}
                        onChange={(e) =>
                          handleInputChange(e, "fieldOrAnimalGroupGuids")
                        }
                        selectInputError={!!errors.fieldOrAnimalGroupGuids}
                        label={t("yieldEventInput.grassFields.text")}
                        name={"fieldOrAnimalGroupGuids"}
                      />
                    )}

                    {values.yieldType === YIELD_TYPES.CROP && (
                      <NameAndGuidMultiSelectDropdown
                        required={true}
                        selectedItems={allArableFields
                          .filter((arableField: ArableField) => {
                            return values.fieldOrAnimalGroupGuids.includes(
                              arableField.guid
                            );
                          })
                          .map((arableField: ArableField) => {
                            return {
                              guid: arableField.guid,
                              name: arableField.name,
                            };
                          })}
                        items={allArableFields.map(
                          (arableField: ArableField) => {
                            return {
                              name: arableField.name,
                              guid: arableField.guid,
                            };
                          }
                        )}
                        disabled={false}
                        onChange={(e) =>
                          handleInputChange(e, "fieldOrAnimalGroupGuids")
                        }
                        selectInputError={!!errors.fieldOrAnimalGroupGuids}
                        label={t("yieldEventInput.arableFields.text")}
                        name={"fieldOrAnimalGroupGuids"}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <YieldTypeRadioButtons
                      disabled={false}
                      onChange={async (event) => {
                        await handleInputChange(event, `yieldType`);
                      }}
                      currentValue={values.yieldType}
                      inputError={errors?.yieldType ? true : false}
                      name={`yieldType`}
                      resetForm={resetForm}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                </Grid>

                <Typography>
                  {t("fertilizer.operational.cost.heading")}
                </Typography>

                <Box
                  my={0}
                  mb={5}
                  display="flex"
                  alignItems="center"
                  gap={4}
                  p={3}
                  sx={{ border: "1px solid grey", borderRadius: 2 }}
                >
                  <Grid container display="flex" alignItems="baseline">
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      display="flex"
                      alignItems="baseline"
                    >
                      <TextField
                        value={values.fuel}
                        type="number"
                        variant="standard"
                        disabled={false}
                        id="fuel"
                        error={errors?.fuel ? true : false}
                        onChange={async (event) => {
                          await handleInputChange(event, `fuel`);
                        }}
                        label={t("fertilizerEvent.fuel.label")}
                        maxRows={1}
                        name={`fuel`}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      display="flex"
                      alignItems="baseline"
                    >
                      <NameAndGuidMultiSelectDropdown
                        required={false}
                        selectedItems={Object.keys(MACHINERY)
                          .filter((key: string) => {
                            return (values.machinery as string[]).includes(key);
                          })
                          .map((key: string) => {
                            return {
                              guid: key,
                              name: t(
                                `yieldEventInput.machinery.options.${key}`
                              ),
                            };
                          })}
                        items={Object.keys(MACHINERY).map((key: string) => {
                          return {
                            name: t(`yieldEventInput.machinery.options.${key}`),
                            guid: key,
                          };
                        })}
                        disabled={false}
                        onChange={(e) => handleInputChange(e, "machinery")}
                        selectInputError={!!errors.machinery}
                        label={t("fertilizerEventInput.machinery.label")}
                        name={"machinery"}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      display="flex"
                      alignItems="baseline"
                    >
                      <TextField
                        value={values.operationalCost}
                        type="number"
                        variant="standard"
                        disabled={false}
                        id="fuel"
                        error={errors?.operationalCost ? true : false}
                        onChange={async (event) => {
                          await handleInputChange(event, `operationalCost`);
                        }}
                        label={t("fertilizerEvent.operationalCost.label")}
                        maxRows={1}
                        name={`operationalCost`}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Container>

              <Container style={{ textAlign: "left", marginBottom: 40 }}>
                <Form id="yield-event-input-form">
                  <FieldArray name="yieldAmounts">
                    {({ remove, push }) => {
                      return (
                        <div key={"lsls"}>
                          {values.yieldAmounts.length > 0 &&
                            values.yieldAmounts.map((yieldDetail, index) => {
                              let possibleYieldTypeValues: {
                                [key: string]: string;
                              } = {};
                              let yieldTypeTPrefix = "";
                              let possibleUnitTypeValues = undefined;
                              let unitTypeTPrefix = "";
                              if (values.yieldType === YIELD_TYPES.GRASS) {
                                possibleYieldTypeValues = GRASS_YIELD_TYPES;
                                yieldTypeTPrefix =
                                  "yieldEvent.yieldType.option";
                                possibleUnitTypeValues = GRASS_UNITS;
                                unitTypeTPrefix = "yieldEvent.unitType.option";
                              } else if (
                                values.yieldType === YIELD_TYPES.CROP
                              ) {
                                possibleYieldTypeValues = getPossibleCropYields(
                                  values.fieldOrAnimalGroupGuids
                                );
                                yieldTypeTPrefix =
                                  "yieldEvent.yieldType.option";
                                possibleUnitTypeValues = CROP_UNITS;
                                unitTypeTPrefix = "yieldEvent.unitType.option";
                              } else if (
                                values.yieldType === YIELD_TYPES.MEAT
                              ) {
                                possibleYieldTypeValues[YIELD_TYPES.MEAT] =
                                  YIELD_TYPES[YIELD_TYPES.MEAT];
                                yieldTypeTPrefix = "yieldEvent.yieldType";
                                possibleUnitTypeValues = MEAT_UNITS;
                                unitTypeTPrefix = "yieldEvent.unitType.option";
                              } else {
                                possibleYieldTypeValues[YIELD_TYPES.MILK] =
                                  YIELD_TYPES[YIELD_TYPES.MILK];
                                yieldTypeTPrefix = "yieldEvent.yieldType";
                                possibleUnitTypeValues = MILK_UNITS;
                                unitTypeTPrefix = "yieldEvent.unitType.option";
                              }

                              return (
                                <div key={`index - ${index} `}>
                                  <EnterYieldEventYieldDetails
                                    handleInputChange={handleInputChange}
                                    index={index}
                                    errors={
                                      errors?.yieldAmounts?.[index] as
                                        | FormikErrors<YieldDetailsFormData>
                                        | undefined
                                    }
                                    yieldDetail={yieldDetail}
                                    remove={() => remove(index)}
                                    possibleYieldTypeValues={
                                      possibleYieldTypeValues
                                    }
                                    yieldTypeTPrefix={yieldTypeTPrefix}
                                    possibleUnitTypeValues={
                                      possibleUnitTypeValues
                                    }
                                    unitTypeTPrefix={unitTypeTPrefix}
                                    setFieldValue={setFieldValue}
                                  />
                                </div>
                              );
                            })}
                          <Button
                            onClick={() => {
                              const newValues = cloneDeep(newYieldAmount);
                              if (values.yieldType === YIELD_TYPES.MEAT) {
                                newValues.detailYieldType = YIELD_TYPES.MEAT;
                              }
                              if (values.yieldType === YIELD_TYPES.MILK) {
                                newValues.detailYieldType = YIELD_TYPES.MILK;
                              }
                              push(newValues);
                            }}
                            sx={{ background: "#ffffff", color: "#5E5E5E" }}
                          >
                            <Typography>
                              {" "}
                              + {t("yieldEventInput.addYield")}
                            </Typography>
                          </Button>
                        </div>
                      );
                    }}
                  </FieldArray>
                </Form>
              </Container>
              <Container style={{ textAlign: "center", marginBottom: 40 }}>
                <Grid container>
                  <Grid item xs={6} sm={6} display="flex" alignItems="baseline">
                    <StyledTextField
                      id="yield-event-input-note"
                      label={t("reseedEvent.notes.label")}
                      autoFocus
                      value={values.note}
                      helperText={errors.note}
                      error={!!errors.note}
                      fullWidth
                      onChange={(e) => handleInputChange(e, "note")}
                      name={"note"}
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Container>
              <CropTypeMissingDialog
                arableFields={allArableFields.filter(
                  (arableField: ArableField) =>
                    values?.fieldOrAnimalGroupGuids.includes(arableField.guid)
                )}
                crops={values.yieldAmounts}
                open={missingCropTypesDialogOpen}
                setClose={() => setMissingCropTypesDialogOpen(false)}
              />
            </>
          );
        }}
      </Formik>
      <Divider />
      <ButtonContainer>
        <ButtonGroup>
          <Button color="secondary" onClick={handleCancel}>
            {t("edit.button.cancel")}
          </Button>
          <Button color="primary" type="submit" form="yield-event-input-form">
            {t("edit.button.save")}
          </Button>
        </ButtonGroup>
        <ConfirmCancellationDialog
          dialogOpen={open}
          closeDialog={() => setOpen(false)}
          onConfirmCancel={() => handleConfirmationClose()}
          onCancelCancel={() => setOpen(false)}
        />
      </ButtonContainer>
    </>
  );

  function handleConfirmationClose() {
    mixpanel.track("YieldEvent creation cancelled");
    handleClose();
  }

  function handleCancel() {
    setOpen(true);
  }
}

const StyledTextField = styled(TextField)(
  ({ theme }) => css`
                          min - width: 300px;
                    padding - bottom: ${theme.spacing(2)};
                    `
);
