import { Button, Grid, TextField, Typography, Box } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "common/locales";
import StyledCoverAppBar from "../bulkCoverInput/StyledCoverAppBar";
import GrassTypeDropdown from "../fieldEdit/GrassTypeDropdown";
import { Field, GRASS_TYPE, selectAllFields } from "model/fieldSlice";
import {
  RESEED_TOTAL_AMOUNT_UNIT,
  RESEED_TYPE,
  SEEDING_METHOD,
} from "model/reseedEventSlice";
import { useIntl } from "react-intl";
import EnterDate from "../general/EnterDate";
import { Dispatch, MouseEventHandler, SetStateAction } from "react";
import SeedingMethodDropdown from "./ReseedMethodDropDown";
import NameAndGuidMultiSelectDropdown, {
  NameAndGuid,
} from "features/general/NameAndGuidMultiSelectDropdown";
import { MACHINERY } from "model";
import ReseedTypeRadioButtons from "./ReseedTypeRadioButtons";
import GenericDropdown from "features/general/GenericDropdown";

interface Props {
  selectedFields: NameAndGuid[];
  setSelectedFields: Dispatch<SetStateAction<Array<NameAndGuid>>>;
  setNewGrassType: Dispatch<SetStateAction<GRASS_TYPE | undefined>>;
  setSeedingMethod: Dispatch<SetStateAction<SEEDING_METHOD | undefined>>;
  handleSave: MouseEventHandler<HTMLButtonElement>;
  setNotes: Dispatch<SetStateAction<string>>;
  notes: string;
  reseedDate: Date | null;
  setReseedDate: Dispatch<SetStateAction<Date | null>>;
  grassTypeError: boolean;
  setGrassTypeError: Dispatch<SetStateAction<boolean>>;
  seedingMethodError: boolean;
  setSeedingMethodError: Dispatch<SetStateAction<boolean>>;
  reseedDateError: boolean;
  setReseedDateError: Dispatch<SetStateAction<boolean>>;
  fieldsSelectInputError: boolean;
  setFieldsSelectInputError: Dispatch<SetStateAction<boolean>>;
  newGrassType?: string;
  seedingMethod?: string;
  machinery: MACHINERY[];
  setMachinery: Dispatch<SetStateAction<MACHINERY[]>>;
  fuel: number;
  setFuel: Dispatch<SetStateAction<number>>;
  operationalCost: number;
  setOperationalCost: Dispatch<SetStateAction<number>>;
  productCost: number;
  setProductCost: Dispatch<SetStateAction<number>>;
  enableFieldSelection: boolean;
  onCancel: () => void;
  reseedType: RESEED_TYPE;
  setReseedType: Dispatch<SetStateAction<RESEED_TYPE>>;
  productName: string;
  setProductName: Dispatch<SetStateAction<string>>;
  seedingRate: number;
  setSeedingRate: Dispatch<SetStateAction<number>>;
  totalAmountUnit: RESEED_TOTAL_AMOUNT_UNIT;
  setTotalAmountUnit: Dispatch<SetStateAction<RESEED_TOTAL_AMOUNT_UNIT>>;
  totalAmount: number;
  setTotalAmount: Dispatch<SetStateAction<number>>;
}

export default function EnterReseedEvents({
  selectedFields,
  setSelectedFields,
  setNewGrassType,
  handleSave,
  setNotes,
  reseedDate,
  setReseedDate,
  grassTypeError,
  setGrassTypeError,
  setSeedingMethod,
  seedingMethodError,
  setSeedingMethodError,
  reseedDateError,
  setReseedDateError,
  fieldsSelectInputError,
  setFieldsSelectInputError,
  newGrassType,
  seedingMethod,
  setMachinery,
  machinery,
  setFuel,
  fuel,
  operationalCost,
  setOperationalCost,
  productCost,
  setProductCost,
  notes,
  enableFieldSelection,
  onCancel,
  reseedType,
  setReseedType,
  productName,
  setProductName,
  seedingRate,
  setSeedingRate,
  totalAmount,
  setTotalAmountUnit,
  setTotalAmount,
  totalAmountUnit,
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { formatMessage: f } = useIntl();
  const allFields = useSelector(selectAllFields);

  const handleGrassTypeChange = (value: GRASS_TYPE | undefined) => {
    setNewGrassType(value);
  };

  const handleSeedingMethodChange = (value: SEEDING_METHOD | undefined) => {
    setSeedingMethod(value);
  };

  const totalAmountUnitError = totalAmount
    ? totalAmountUnit === ("" as unknown as RESEED_TOTAL_AMOUNT_UNIT)
    : false;

  return (
    <Grid
      container
      rowSpacing={3}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      flexDirection={"column"}
    >
      <Grid item xs={12} sm={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h5">{t("reseedEvent.heading")}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <EnterDate
              disabled={false}
              date={reseedDate}
              setDate={setReseedDate}
              dateError={reseedDateError}
              name={"someName"}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <ReseedTypeRadioButtons
              onChange={(newReseedType: RESEED_TYPE) => {
                setReseedType(newReseedType);
              }}
              currentValue={reseedType}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <NameAndGuidMultiSelectDropdown
              required={false}
              selectedItems={selectedFields}
              items={allFields.map((field: Field) => {
                return {
                  name: field.name,
                  guid: field.guid,
                };
              })}
              disabled={!enableFieldSelection}
              onChange={(e: SelectChangeEvent<string[]>) => {
                let selectedGuids: string[] = [];
                if (
                  typeof e.target.value === "string" ||
                  e.target.value instanceof String
                ) {
                  selectedGuids = e.target.value.split(",");
                } else {
                  selectedGuids = e.target.value;
                }
                const selectedFieldsList: NameAndGuid[] = [];
                selectedGuids.forEach((fieldGuid: string) => {
                  for (const field of allFields) {
                    if (field.guid === fieldGuid) {
                      selectedFieldsList.push({
                        guid: field.guid,
                        name: field.name,
                      });
                    }
                  }
                });

                setSelectedFields(selectedFieldsList);
                setFieldsSelectInputError(
                  !selectedFieldsList || selectedFieldsList.length <= 0
                );
              }}
              selectInputError={fieldsSelectInputError}
              label={t("reseedEvents.selectFields.text")}
              name={"someName"}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <GrassTypeDropdown
              disabled={false}
              onChange={(value?: GRASS_TYPE) => {
                handleGrassTypeChange(value);
                if (value) {
                  setGrassTypeError(false);
                }
              }}
              currentGrassType={newGrassType ?? ""}
              grassTypeInputError={grassTypeError}
              label={"reseedEvent.grassType.text"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <SeedingMethodDropdown
              onChange={(value?: SEEDING_METHOD) => {
                handleSeedingMethodChange(value);
                if (value) {
                  setSeedingMethodError(false);
                } else {
                  setSeedingMethodError(true);
                }
              }}
              seedingMethod={seedingMethod ?? ""}
              seedingMethodInputError={seedingMethodError}
              label={"reseedEvent.seedingMethod.text"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} mb={5}>
        <Grid container spacing={3} alignItems="baseline" display="flex">
          <Grid item xs={12} sm={4} alignItems="baseline" display="flex">
            <TextField
              value={productName}
              type="text"
              variant="standard"
              disabled={false}
              id="productName"
              onChange={(event) => {
                setProductName(event.target.value);
              }}
              label={t("reseedEvent.productName.label")}
              maxRows={1}
              name={`productName`}
            />
          </Grid>
          <Grid item xs={12} sm={4} alignItems="baseline" display="flex">
            <TextField
              value={seedingRate}
              type="number"
              variant="standard"
              disabled={false}
              id="fuel"
              error={seedingRate !== undefined && seedingRate < 0}
              onChange={(event) => {
                if (event.target.value !== "") {
                  setSeedingRate(+event.target.value);
                } else {
                  setSeedingRate(event.target.value as unknown as number);
                }
              }}
              label={t("reseedEvent.seedingRate.label")}
              maxRows={1}
              name={`seedingRate`}
            />
          </Grid>
        </Grid>
      </Grid>

      <Typography>{t("reseed.amount.cost.heading")}</Typography>
      <Box
        my={0}
        mb={5}
        display="flex"
        alignItems="center"
        gap={4}
        p={3}
        sx={{ border: "1px solid grey", borderRadius: 2 }}
      >
        <Grid container display="flex" alignItems="baseline">
          <Grid item xs={12} sm={3} display="flex" alignItems="baseline">
            <TextField
              value={totalAmount}
              type="number"
              variant="standard"
              disabled={false}
              id="fuel"
              error={totalAmount !== undefined && totalAmount < 0}
              onChange={(event) => {
                if (event.target.value !== "") {
                  setTotalAmount(+event.target.value);
                } else {
                  setTotalAmount(event.target.value as unknown as number);
                }
              }}
              label={t("reseedEvent.totalAmount.label")}
              maxRows={1}
              name={`totalAmount`}
            />
          </Grid>
          <Grid item xs={12} sm={6} display="flex" alignItems="baseline">
            <GenericDropdown
              required={false}
              disabled={false}
              onChange={(event) => {
                setTotalAmountUnit(
                  event.target.value as RESEED_TOTAL_AMOUNT_UNIT
                );
              }}
              currentValue={totalAmountUnit}
              inputError={totalAmountUnitError}
              label={"reseedEvent.totalAmountUnit.label"}
              name={`totalAmountUnit`}
              possibleValues={RESEED_TOTAL_AMOUNT_UNIT}
              tPrefix={"reseedEvent.totalAmountUnit.label"}
            />
          </Grid>
          <Grid item xs={12} sm={3} display="flex" alignItems="baseline">
            <TextField
              value={productCost}
              type="number"
              variant="standard"
              disabled={false}
              id="productCost"
              error={productCost !== undefined && productCost < 0}
              onChange={(event) => {
                if (event.target.value !== "") {
                  setProductCost(+event.target.value);
                } else {
                  setProductCost(event.target.value as unknown as number);
                }
              }}
              label={t("fertilizerEvent.productCost.label")}
              maxRows={1}
              name={`productCost`}
            />
          </Grid>
        </Grid>
      </Box>
      <Typography>{t("reseed.operational.cost.heading")}</Typography>

      <Box
        my={0}
        mb={5}
        display="flex"
        alignItems="center"
        gap={4}
        p={3}
        sx={{ border: "1px solid grey", borderRadius: 2 }}
      >
        <Grid container display="flex" alignItems="baseline">
          <Grid item xs={12} sm={3} display="flex" alignItems="baseline">
            <TextField
              value={fuel}
              type="number"
              variant="standard"
              disabled={false}
              id="fuel"
              error={fuel !== undefined && fuel < 0}
              onChange={(event) => {
                if (event.target.value !== "") {
                  setFuel(+event.target.value);
                } else {
                  setFuel(event.target.value as unknown as number);
                }
              }}
              label={t("fertilizerEvent.fuel.label")}
              maxRows={1}
              name={`fuel`}
            />
          </Grid>
          <Grid item xs={12} sm={6} display="flex" alignItems="baseline">
            <NameAndGuidMultiSelectDropdown
              required={false}
              selectedItems={Object.keys(MACHINERY)
                .filter((key: string) => {
                  return (machinery as string[]).includes(key);
                })
                .map((key: string) => {
                  return {
                    guid: key,
                    name: t(`yieldEventInput.machinery.options.${key}`),
                  };
                })}
              items={Object.keys(MACHINERY).map((key: string) => {
                return {
                  name: t(`yieldEventInput.machinery.options.${key}`),
                  guid: key,
                };
              })}
              disabled={false}
              onChange={(e) => setMachinery(e.target.value as MACHINERY[])}
              selectInputError={false}
              label={t("fertilizerEventInput.machinery.label")}
              name={"machinery"}
            />
          </Grid>
          <Grid item xs={12} sm={3} display="flex" alignItems="baseline">
            <TextField
              value={operationalCost}
              type="number"
              variant="standard"
              disabled={false}
              id="operationalCost"
              error={operationalCost !== undefined && operationalCost < 0}
              onChange={(event) => {
                if (event.target.value !== "") {
                  setOperationalCost(+event.target.value);
                } else {
                  setOperationalCost(event.target.value as unknown as number);
                }
              }}
              label={t("fertilizerEvent.operationalCost.label")}
              maxRows={1}
              name={`operationalCost`}
            />
          </Grid>
        </Grid>
      </Box>

      <Grid item xs={12} sm={12}>
        <Grid container spacing={3} alignItems="baseline" display="flex">
          <Grid item xs={12} sm={4} alignItems="baseline" display="flex">
            <TextField
              value={notes}
              variant="standard"
              disabled={false}
              id="reseedNotes"
              onChange={(event) => {
                setNotes(event.target.value);
              }}
              label={t("reseedEvent.notes.label")}
              multiline
              maxRows={12}
            />
          </Grid>
        </Grid>
      </Grid>
      <StyledCoverAppBar>
        <Button
          onClick={() => {
            onCancel();
          }}
          color="secondary"
        >
          {f({ id: "addGrassType.cancel.label" })}
        </Button>
        <Button color="primary" onClick={handleSave}>
          {t("addGrassType.save.label")}
        </Button>
      </StyledCoverAppBar>
    </Grid>
  );
}
