import { MACHINERY, FieldPreparationEvent, PURCHASE_CURRENCY } from "model";

export type FieldPreparationEventFormData = {
  note?: string;
  machinery: MACHINERY[];
  fuel: number;
  operationalCost?: number;
  fieldPreparationEventDate: Date;
  fieldGuids: string[];
};
export const convertFromFormInput = (
  fieldPreparationFormData: FieldPreparationEventFormData
): FieldPreparationEvent => {
  const operationalCost =
    (fieldPreparationFormData.operationalCost as unknown as string) === ""
      ? undefined
      : fieldPreparationFormData.operationalCost;
  return {
    note: fieldPreparationFormData.note,
    machinery: fieldPreparationFormData.machinery,
    fuel: fieldPreparationFormData.fuel,
    operationalCost: operationalCost,
    operationalCostUnit: operationalCost ? PURCHASE_CURRENCY.GBP : undefined,
    fieldPreparationEventDate: new Date(
      fieldPreparationFormData.fieldPreparationEventDate
    ).toISOString(),
    fieldGuids: fieldPreparationFormData.fieldGuids,
    guid: "",
  };
};
