import { Dispatch, SetStateAction, useState } from "react";
import { Container, Dialog } from "@mui/material";
import { useSelector } from "react-redux";
import {
  MACHINERY,
  PURCHASE_CURRENCY,
  RootState,
  getAllReseedEventsForFarm,
  useAppDispatch,
} from "model";
import styled from "styled-components";
import { useNotification } from "app/NotificationProvider";
import { useTranslation } from "common/locales";
import { mixpanel } from "common/analytics";
import { GRASS_TYPE, fetchFieldsById } from "model/fieldSlice";
import {
  RESEED_TOTAL_AMOUNT_UNIT,
  RESEED_TYPE,
  SEEDING_METHOD,
} from "model/reseedEventSlice";
import { updateReseedEvent, Field, ReseedEvent } from "model";
import { useIntl } from "react-intl";
import EnterReseedEvents from "features/bulkReseedEventInput/EnterReseedEvents";
import { NameAndGuid } from "features/general/NameAndGuidMultiSelectDropdown";
const StyledContainer = styled(Container)`
  padding-top: 50px;
  padding-bottom: 70px;
`;

export default function UpdateReseedEvent({
  fields,
  reseedEvent,
  dialogOpen,
  setDialogOpen,
}: {
  fields: Field[];
  reseedEvent: ReseedEvent;
  dialogOpen: boolean;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const { t } = useTranslation();

  const currentFarmId = useSelector(
    (state: RootState) => state.farms.currentFarmId
  );

  const handleClose = () => {
    setDialogOpen(false);
  };

  const [newGrassType, setNewGrassType] = useState<GRASS_TYPE | undefined>(
    reseedEvent.newGrassType
  );

  const [grassTypeError, setGrassTypeError] = useState<boolean>(false);

  const [seedingMethod, setSeedingMethod] = useState<
    SEEDING_METHOD | undefined
  >(reseedEvent.seedingMethod);

  const [seedingMethodError, setSeedingMethodError] = useState<boolean>(false);

  const [selectedFields, setSelectedFields] = useState<NameAndGuid[]>(
    fields.map((field: Field) => ({ name: field.name, guid: field.guid }))
  );

  const [notes, setNotes] = useState<string>(reseedEvent.note ?? "");

  const [reseedDate, setReseedDate] = useState<Date | null>(
    new Date(Date.parse(reseedEvent.reseedDate))
  );

  const [reseedDateError, setReseedDateError] = useState<boolean>(false);

  const [machinery, setMachinery] = useState<MACHINERY[]>(
    reseedEvent.machinery ?? []
  );

  const [fuel, setFuel] = useState<number>(
    reseedEvent.fuel ?? ("" as unknown as number)
  );

  const [operationalCost, setOperationalCost] = useState<number>(
    reseedEvent.operationalCost ?? ("" as unknown as number)
  );

  const [productCost, setProductCost] = useState<number>(
    reseedEvent.productCost ?? ("" as unknown as number)
  );

  const [reseedType, setReseedType] = useState<RESEED_TYPE>(
    reseedEvent.reseedType
  );

  const [fieldsSelectInputError, setFieldsSelectInputError] =
    useState<boolean>(false);

  const [seedingRate, setSeedingRate] = useState<number>(
    reseedEvent.seedingRate ?? ("" as unknown as number)
  );

  const [productName, setProductName] = useState<string>(
    reseedEvent.productName ?? ""
  );

  const [totalAmountUnit, setTotalAmountUnit] =
    useState<RESEED_TOTAL_AMOUNT_UNIT>(
      reseedEvent.totalAmountUnit ?? ("" as unknown as RESEED_TOTAL_AMOUNT_UNIT)
    );

  const [totalAmount, setTotalAmount] = useState<number>(
    reseedEvent.totalAmount ?? ("" as unknown as number)
  );

  function callUpdateReseedEvent() {
    if (!newGrassType || !reseedDate || !seedingMethod) {
      return;
    }

    const totalAmountUnitForPost =
      totalAmountUnit === ("" as unknown as RESEED_TOTAL_AMOUNT_UNIT)
        ? undefined
        : totalAmountUnit;
    const finalOperationalCost =
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (operationalCost as unknown as any) !== "" ? operationalCost : undefined;
    const finalProductCost =
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (productCost as unknown as any) !== "" ? productCost : undefined;
    return dispatch(
      updateReseedEvent({
        fieldGuids: selectedFields.map(
          (selectedField: NameAndGuid) => selectedField.guid ?? ""
        ),
        newGrassType: newGrassType,
        guid: reseedEvent.guid,
        note: notes ?? "",
        seedingMethod: seedingMethod,
        reseedDate: reseedDate.toISOString(),
        fuel: fuel,
        operationalCost: finalOperationalCost,
        productCost: finalProductCost,
        operationalCostUnit: finalOperationalCost
          ? PURCHASE_CURRENCY.GBP
          : undefined,
        productCostUnit: finalProductCost ? PURCHASE_CURRENCY.GBP : undefined,
        machinery: machinery,
        reseedType: reseedType,
        seedingRate: seedingRate,
        productName: productName,
        totalAmount: totalAmount ? totalAmount : undefined,
        totalAmountUnit: totalAmount ? totalAmountUnitForPost : undefined,
      })
    );
  }

  async function handleSave() {
    // check for wrong input
    if (!newGrassType) {
      setGrassTypeError(true);
    }
    if (!reseedDate) {
      setReseedDateError(true);
    }
    if (!seedingMethod) {
      setSeedingMethodError(true);
    }

    if (
      !newGrassType ||
      !reseedDate ||
      !seedingMethod ||
      reseedDateError ||
      (fuel && fuel < 0) ||
      (operationalCost && operationalCost < 0) ||
      (productCost && productCost < 0) ||
      (seedingRate && seedingRate < 0) ||
      (totalAmount && totalAmount < 0) ||
      (totalAmount &&
        totalAmountUnit === ("" as unknown as RESEED_TOTAL_AMOUNT_UNIT))
    ) {
      return;
    }

    await callUpdateReseedEvent();
    await dispatch(
      fetchFieldsById(
        selectedFields.map(
          (selectedField: NameAndGuid) => selectedField.guid ?? ""
        )
      )
    ); // updating the grass type if needed
    await dispatch(getAllReseedEventsForFarm({ farmId: currentFarmId ?? "" }));
    notify(t("editReseedEvent.success"));
    mixpanel.track("Update re seed event successful");
    handleClose();
  }

  return (
    <Dialog
      fullScreen
      disableEscapeKeyDown
      open={dialogOpen}
      onClose={handleClose}
    >
      <StyledContainer>
        <EnterReseedEvents
          selectedFields={selectedFields}
          setSelectedFields={setSelectedFields}
          enableFieldSelection={true}
          setNewGrassType={setNewGrassType}
          setNotes={setNotes}
          reseedDate={reseedDate}
          setReseedDate={setReseedDate}
          grassTypeError={grassTypeError}
          reseedDateError={reseedDateError}
          setGrassTypeError={setGrassTypeError}
          setSeedingMethod={setSeedingMethod}
          seedingMethodError={seedingMethodError}
          setSeedingMethodError={setSeedingMethodError}
          setReseedDateError={setReseedDateError}
          newGrassType={newGrassType}
          notes={notes}
          seedingMethod={seedingMethod}
          machinery={machinery}
          setMachinery={setMachinery}
          fuel={fuel}
          setFuel={setFuel}
          handleSave={handleSave}
          fieldsSelectInputError={fieldsSelectInputError}
          setFieldsSelectInputError={setFieldsSelectInputError}
          onCancel={() => {
            mixpanel.track("Update re seed event cancelled");
            handleClose();
          }}
          reseedType={reseedType}
          setReseedType={setReseedType}
          productName={productName}
          setProductName={setProductName}
          seedingRate={seedingRate}
          setSeedingRate={setSeedingRate}
          totalAmountUnit={totalAmountUnit}
          setTotalAmountUnit={setTotalAmountUnit}
          totalAmount={totalAmount}
          setTotalAmount={setTotalAmount}
          operationalCost={operationalCost}
          setOperationalCost={setOperationalCost}
          productCost={productCost}
          setProductCost={setProductCost}
        />
      </StyledContainer>
    </Dialog>
  );
}
